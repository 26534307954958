import axios from "@/plugins/apiService.js";
const state = {
  authenticated: false,
  user: "",
  idToken: "",
  user_role:"",
};

// mutations
const mutations = {
  SET_AUTHENTICATED(state, value) {
    state.authenticated = value;
  },
  SET_USER(state, value) {
    state.user = value;
  },
  SET_ID_TOKEN(state, value) {
    state.idToken = value;
  },
  SET_USER_ROLE(state , value) {
    state.user_role = value;  
  }
};

// getters
const getters = {
  authenticated(state) {
    return state.authenticated;
  },
  user(state) {
    return state.user;
  },
  getIdToken(state) {
    return state.idToken;
  },
  getUserRole(state) {
    return state.user_role;
  },
};
//actions
const actions = {
  // eslint-disable-next-line no-unused-vars
  async login({ commit }, data) {
    let resp = await axios.post("/api/User/Login", data);
    if (resp.data.token) {
    //   commit("SET_USER", JSON.stringify(resp.data.user));
      commit("SET_USER_ROLE", resp.data.User.User_Role);
      commit("SET_ID_TOKEN", resp.data.token);
      commit("SET_AUTHENTICATED", true);
    } else {
    //   commit("SET_USER", "");
      commit("SET_USER_ROLE",null);
      commit("SET_ID_TOKEN", "");
      commit("SET_AUTHENTICATED", false);
    }
    return resp;
  },
  logout({commit}){
    console.log('here')
    commit("SET_USER_ROLE",null);
    commit("SET_ID_TOKEN", "");
    commit("SET_AUTHENTICATED", false);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
