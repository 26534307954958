/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
// import Login from "@/views/auth/login";
import Dashboard from "@/views/dashboard/dashboard";
import SignUpWith from "@/views/auth/signUpWith.vue";
import signupType from "@/views/auth/signupType.vue";
import mainSignUp from "@/views/auth/signupMain.vue";
import signupLayout from "@/components/signup/signUpLayout.vue";
import MainLayout from "@/components/layoutMain/MainLayout.vue";
import Terms_and_Conditions from "@/views/auth/Terms_and_Conditions.vue";
import store from "@/store";
Vue.use(VueRouter);
const routes = [
  // {
  //   path: "/login",
  //   name: "login",
  //   component: Login,
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  { path: '/terms_and_conditions', component: Terms_and_Conditions },
  {
    path: "/",
    redirect:'/sign-up/type',
    component: MainLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/",
        name: "Dash",
        component: Dashboard,
      },
    ],
  },
  {
    path: "/sign-up-with",
    name: "signUpWith",
    component: SignUpWith,
    meta: {
      requiresAuth: false,
    },
      beforeEnter:((to,from,next)=>{
       const isaccountSelected = JSON.parse(localStorage.getItem('account_type'))
          // const isaccountSelected = store.state.signup.signaccountType;
           var url_string = window.location.href
           var url = new URL(url_string);
           var c = url.searchParams.get("Account_type");
           console.log(isaccountSelected,'selected_account')
          if(isaccountSelected && isaccountSelected.Account_type=='2'){
              return next()
          }else{
            return next('/sign-up/type');
          }
        }),
  },
  {
    path: "/sign-up",
    component: signupLayout,
    meta: {
      requiresAuth: false,
    },
    
    children: [
      {
        path: "type",
        name: "signupType",
        component: signupType,
      },
      {
        path: "account",
        name: "MainSignUp",
        component: mainSignUp,
        beforeEnter:((to,from,next)=>{
          let account = JSON.parse(localStorage.getItem('account_type'))
          // const isaccountSelected = store.state.signup.signaccountType;
          if(account){
              return next()
          }else{
            return next('/sign-up/type');
          }
        }),
      },
      
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.state.auth.authenticated;
  const isRequiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const { path, name, params } = to;
  if (isAuthenticated && ["login"].includes(name)) {
      return next("/dashboard");
  } else if (isRequiresAuth && !isAuthenticated) {
    return next("/sign-up/type");
  }
  return next();
});
export default router;
