import axios from 'axios';
import store from '@/store'
// import { useStore } from 'vuex'
// const store = useStore()
const customAxios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    // timeout: 10000, 
});
const requestHandler = request => {
    let idToken = store.state.signup.current_step   
    let headers={
        "x-access-token": idToken,
    }
    request.headers=headers  
    return request;
};
const responseHandler = response => {
    // if(response.status==401){
    //    store.dispatch('auth/logout')
    // }
   return response;
};

const errorHandler = error => {
    return Promise.reject(error);
};

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
 );


export default customAxios;