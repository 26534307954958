<template>
        <div class="d-flex flex-column flex-root">
            <!--begin::Page-->
            <div class="page d-flex flex-row flex-column-fluid">
                <!--begin::Wrapper-->
                <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <!--begin::Header-->
                    <div id="Organization_header" include-html="./organization-header.html" class="header" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
                        <!--begin::Container-->
                        <div class="container-xxl d-flex flex-grow-1 flex-stack">
                            <!--begin::Header Logo-->
                            <div class="d-flex align-items-center me-5">
                                <a href="dashboard.html">
                                    <img alt="Logo" :src="COMPANY_LOGO" class="h-40px h-lg-40px" />
                                </a>
                            </div>
                            <!--end::Header Logo-->
                            <!--begin::Topbar-->
                            <div class="d-flex align-items-center flex-shrink-0">
                                <p class="d-flex fw-bolder my-1 fs-5" style="color: rgb(80 64 157) ">Switch Account Type</p>
                                <!--Start Admin Switcher-->
                                <div class="d-flex d_switcher align-items-center ms-3 ms-lg-2 mr-10">
                                    <select class="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success" value="" name="forma" onchange="location = this.value;">
                                        <option value="" selected disabled>Switch Dashboard</option>
                                        <option value="dashboard.html" selected>Organization</option>
                                        <option value="supplier/dashboard.html">Supplier</option>
                                        <option value="freelancer/dashboard.html">Freelancer</option>
                                        <option value="auditfirms/dashboard.html">Audit Firm</option>
                                        <option value="regulator/dashboard.html">Regulator</option>
                                    </select>
                                </div>
                                <!--End Admin Switcher-->
                                <!--begin::Search-->
                                <div id="kt_header_search" class="d-flex align-items-center w-lg-250px" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="enter" data-kt-search-layout="menu" data-kt-search-responsive="lg" data-kt-menu-trigger="auto" data-kt-menu-permanent="true" data-kt-menu-placement="bottom-end">
                                    <!--begin::Tablet and mobile search toggle-->
                                    <div data-kt-search-element="toggle" class="d-flex d-lg-none align-items-center">
                                        <div class="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                                            <span class="svg-icon svg-icon-1 svg-icon-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </div>
                                    </div>
                                    <!--end::Tablet and mobile search toggle-->
                                    <!--begin::Form(use d-none d-lg-block classes for responsive search)-->
                                    <form data-kt-search-element="form" class="d-none d-lg-block w-100 position-relative mb-2 mb-lg-0" autocomplete="off">
                                        <!--begin::Hidden input(Added to disable form autocomplete)-->
                                        <input type="hidden" />
                                        <!--end::Hidden input-->
                                        <!--begin::Icon-->
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen004.svg-->
                                        <span class="svg-icon svg-icon-2 svg-icon-gray-700 position-absolute top-50 translate-middle-y ms-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="black" />
                                                <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="black" />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                        <!--end::Icon-->
                                        <!--begin::Input-->
                                        <input type="text" class="form-control bg-transparent ps-13 fs-7 h-40px" name="search" value="" placeholder="Quick Search" data-kt-search-element="input" />
                                        <!--end::Input-->
                                        <!--begin::Spinner-->
                                        <span class="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5" data-kt-search-element="spinner">
                                            <span class="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                                        </span>
                                        <!--end::Spinner-->
                                        <!--begin::Reset-->
                                        <span class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4" data-kt-search-element="clear">
                                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                                            <span class="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <!--end::Reset-->
                                    </form>
                                    <!--end::Form-->
                                    <!--begin::Menu-->
                                    <div data-kt-search-element="content" class="menu menu-sub menu-sub-dropdown w-300px w-md-350px py-7 px-7 overflow-hidden">
                                        <!--begin::Wrapper-->
                                        <div data-kt-search-element="wrapper">
                                            <!--end::Empty-->
                                        </div>
                                        <!--end::Wrapper-->
                                    </div>
                                    <!--end::Menu-->
                                </div>
                                <!--end::Search-->
                                <!--begin::Activities-->
                                <div class="d-flex align-items-center ms-3 ms-lg-2">
                                    <!--begin::Menu- wrapper-->
                                    <div class="btn btn-icon btn-active-light btn-active-color-primary btn-outline btn-outline-secondary w-30px h-30px w-lg-40px h-lg-40px position-relative menu-dropdown" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                        <!--begin::Svg Icon | path: icons/duotune/abstract/abs038.svg-->
                                        <span class="svg-icon svg-icon-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="black"></path>
                                                <path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="black"></path>
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                    </div>
                                    <!--begin::Menu-->
                                    <div class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" data-kt-menu="true" style="z-index: 105; position: fixed; inset: 0px 0px auto auto; margin: 0px; transform: translate(-210px, 93px);" data-popper-placement="bottom-end">
                                        <div class="container topnotificationbar">
                                            <div class="row heading">
                                                <div class="col-md-12">
                                                    <span class="notification_title fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">Notifications</span>

                                                    <a href="Notification.html" class="btn btn-xs btn-primary ">View All </a>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="head_notification_alerts_list">
                                            <li>
                                                <a hre="#" class="active cursor-pointer">
                                                    <div class="d-flex align-items-center">
                                                        <!--begin::Text-->
                                                        <div class="symbol symbol-50px me-5">
                                                            <img src="/assets/img/organizations/media/logo-01.jpg" class="" alt="">
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="mb-5"><b>Brad Simmons's</b> has marked your project completed.</p>
                                                            <span class="text-muted d-block fw-bold"><b>20 min ago</b></span>
                                                        </div>
                                                        <div class="flex-grow-1 text-right">

                                                        </div>
                                                        <!--end::Text-->
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a hre="#" class="cursor-pointer">
                                                    <div class="d-flex align-items-center">
                                                        <!--begin::Text-->
                                                        <div class="symbol symbol-50px me-5">
                                                            <img src="/assets/img/organizations/media/logo-01.jpg" class="" alt="">
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="mb-5"><b>Brad Simmons's</b> has marked your project completed.</p>
                                                            <span class="text-muted d-block fw-bold"><b>20 min ago</b></span>
                                                        </div>
                                                        <div class="flex-grow-1 text-right">

                                                        </div>
                                                        <!--end::Text-->
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a hre="#" class="cursor-pointer">
                                                    <div class="d-flex align-items-center">
                                                        <!--begin::Text-->
                                                        <div class="symbol symbol-50px me-5">
                                                            <img src="/assets/img/organizations/media/logo-01.jpg" class="" alt="">
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="mb-5"><b>Brad Simmons's</b> has marked your project completed.</p>
                                                            <span class="text-muted d-block fw-bold"><b>20 min ago</b></span>
                                                        </div>
                                                        <div class="flex-grow-1 text-right">

                                                        </div>
                                                        <!--end::Text-->
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a hre="#" class="cursor-pointer">
                                                    <div class="d-flex align-items-center">
                                                        <!--begin::Text-->
                                                        <div class="symbol symbol-50px me-5">
                                                            <img src="/assets/img/organizations/media/logo-01.jpg" class="" alt="">
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="mb-5"><b>Brad Simmons's</b> has marked your project completed.</p>
                                                            <span class="text-muted d-block fw-bold"><b>20 min ago</b></span>
                                                        </div>
                                                        <div class="flex-grow-1 text-right">

                                                        </div>
                                                        <!--end::Text-->
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="py-3 text-center border-top">
                                            <a href="Notification.html" class="btn btn-color-gray-600 btn-active-color-primary">
                                                View All
                                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                                                <span class="svg-icon svg-icon-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
                                                        <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon-->
                                            </a>
                                        </div>

                                    </div>
                                    <!--end::Menu-->
                                    <!--end::Menu wrapper-->
                                </div>
                                <!--end::Activities-->
                                <!--begin::Chat-->
                                <div class="d-flex align-items-center ms-3 ms-lg-4">
                                    <!--begin::Drawer wrapper-->
                                    <div class="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary w-30px h-30px w-lg-40px h-lg-40px position-relative" id="kt_drawer_chat_toggle">
                                        <!--begin::Svg Icon | path: icons/duotune/communication/com003.svg-->
                                        <span class="svg-icon svg-icon-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="black" />
                                                <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="black" />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                        <!--begin::Bullet-->
                                        <span class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
                                        <!--end::Bullet-->
                                    </div>
                                    <!--end::Drawer wrapper-->
                                </div>
                                <!--end::Chat-->
                                <!--begin::User-->
                                <div class="d-flex align-items-center ms-3 ms-lg-2">
                                    <!--begin::Menu- wrapper-->
                                    <div class="btn btn-icon btn-active-light btn-active-color-primary btn-outline btn-outline-secondary w-30px h-30px w-lg-40px h-lg-40px position-relative menu-dropdown" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                        <!--begin::Svg Icon | path: icons/duotune/abstract/abs038.svg-->
                                        <span class="fa fa-question-circle" style="font-size: 24px">
                                            <!--<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="black"></path>
                            <path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="black"></path>
                        </svg>-->
                                        </span>
                                        <!--end::Svg Icon-->
                                    </div>
                                    <!--begin::Menu-->
                                    <div class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" data-kt-menu="true" style="z-index: 105; position: fixed; inset: 0px 0px auto auto; margin: 0px; transform: translate(-210px, 93px);" data-popper-placement="bottom-end">
                                        <div class="container topnotificationbar">
                                            <div class="row heading">
                                                <div class="col-md-12">
                                                    <span class="notification_title fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">Do you need some help?</span>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="head_notification_alerts_list">
                                            <li>
                                                <a hre="#" class="active cursor-pointer">
                                                    <div class="flex-lg-row-fluid ms-lg-5 ms-xl-5">
                                                        <!--begin::Messenger-->
                                                        <div class="card" id="kt_chat_messenger">

                                                            <!--begin::Card footer-->
                                                            <div class="card-footer pt-4" id="kt_chat_messenger_footer">
                                                                <!--begin::Input-->
                                                                <textarea class="form-control form-control-flush mb-3" rows="1" data-kt-element="input" placeholder="Type a message"></textarea>
                                                                <!--end::Input-->
                                                                <!--begin:Toolbar-->
                                                                <div class="text-right">
                                                                    <!--begin::Send-->
                                                                    <button class="btn btn-primary" type="button" data-kt-element="send">Send</button>
                                                                    <!--end::Send-->
                                                                </div>
                                                                <!--end::Toolbar-->
                                                            </div>
                                                            <!--end::Card footer-->
                                                        </div>
                                                        <!--end::Messenger-->
                                                    </div>
                                                </a>
                                            </li>

                                        </ul>

                                    </div>
                                    <!--end::Menu-->
                                    <!--end::Menu wrapper-->
                                </div>
                                <div class="d-flex align-items-center ms-3 ms-lg-4" id="kt_header_user_menu_toggle">
                                    <!--begin::Menu- wrapper-->
                                    <!--begin::User icon(remove this button to use user avatar as menu toggle)-->
                                    <div class="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary w-30px h-30px w-lg-40px h-lg-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                        <!--begin::Svg Icon | path: icons/duotune/communication/com013.svg-->
                                        <span class="svg-icon svg-icon-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="black" />
                                                <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="black" />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                    </div>
                                    <!--end::User icon-->
                                    <!--begin::User account menu-->
                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
                                        <!--begin::Menu item-->
                                        <div class="menu-item px-3">
                                            <div class="menu-content d-flex align-items-center px-3">
                                                <!--begin::Avatar-->
                                                <div class="symbol symbol-50px me-5">
                                                    <img alt="Logo" src="/assets/img/organizations/media/profile_logo/logo.png" />
                                                </div>
                                                <!--end::Avatar-->
                                                <!--begin::Username-->
                                                <div class="d-flex flex-column">
                                                    <div class="fw-bolder d-flex align-items-center fs-5">Round Solutions LLC</div>
                                                    <!--													<a href="#" class="fw-bold text-muted text-hover-primary fs-7">max@kt.com</a>-->
                                                </div>
                                                <!--end::Username-->
                                            </div>
                                        </div>
                                        <!--end::Menu item-->
                                        <!--begin::Menu separator-->
                                        <div class="separator my-2"></div>
                                        <!--end::Menu separator-->
                                        <!--begin::Menu item-->
                                        <div class="menu-item px-5">
                                            <a href="my-profile.html" class="menu-link px-5">Profile</a>
                                        </div>
                                        <!--end::Menu item-->
                                        <!--begin::Menu item-->
                                        <div class="menu-item px-5 my-1">
                                            <a href="users-list.html" class="menu-link px-5">User Access</a>
                                        </div>
                                        <div class="menu-item px-5 my-1">
                                            <a href="my-settings.html" class="menu-link px-5">Settings</a>
                                        </div>
                                        <!--end::Menu item-->
                                        <!--begin::Menu separator-->
                                        <div class="separator my-2"></div>
                                        <!--end::Menu separator-->
                                        <!--begin::Menu item-->
                                        <div class="menu-item px-5">
                                            <a class="menu-link px-5" @click="dologout">Sign Out</a>
                                        </div>
                                        <!--end::Menu item-->
                                    </div>
                                    <!--end::User account menu-->
                                    <!--end::Menu wrapper-->
                                </div>
                                <!--end::User -->
                                <!--begin::Heaeder menu toggle-->
                                <div class="d-lg-none btn btn-icon btn-active-color-primary btn-outline btn-outline-secondary w-30px h-30px w-lg-40px h-lg-40px ms-3" id="kt_header_menu_toggle">
                                    <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                                    <span class="svg-icon svg-icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                                            <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                                        </svg>
                                    </span>
                                    <!--end::Svg Icon-->
                                </div>
                                <!--end::Heaeder menu toggle-->
                            </div>
                            <!--end::Topbar-->
                        </div>
                        <!--end::Container-->
                        <!--begin::Separator-->
                        <div class="separator"></div>
                        <!--end::Separator-->
                        <!--begin::Container-->
                        <div class="header-menu-container container-xxl d-flex flex-stack h-lg-75px w-100" id="kt_header_nav">
                            <!--begin::Menu wrapper-->
                            <div class="header-menu flex-column flex-lg-row" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
                                <!--begin::Menu-->
                                <div class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch flex-grow-1" id="#kt_header_menu" data-kt-menu="true">
                                    <div class="menu-item me-lg-1">
                                        <div class="menu-item here">
                                            <a class="menu-link py-3" href="dashboard.html">
                                                Dashboard
                                            </a>
                                        </div>
                                    </div>
                                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                                        <span class="menu-link py-3">
                                            <span class="menu-title">Registration</span>
                                            <span class="menu-arrow d-lg-none"></span>
                                        </span>
                                        <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="reg_suppliers_list.html">
                                                    <span class="menu-title">Suppliers</span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="reg_freelancer_list.html">
                                                    <span class="menu-title">Freelancers</span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="auditfirms_list.html">
                                                    <span class="menu-title">Audit Firms</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                                        <span class="menu-link py-3">
                                            <span class="menu-title">Projects</span>
                                            <span class="menu-arrow d-lg-none"></span>
                                        </span>
                                        <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="suppliers_list.html">
                                                    <span class="menu-title">Post Project To Supplier </span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="freelancer_list.html">
                                                    <span class="menu-title">Post Project To Freelancer </span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="post-project.html">
                                                    <span class="menu-title">Post Project for Bid </span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="pending-projects.html">
                                                    <span class="menu-title">Pending Projects</span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="active-projects.html">
                                                    <span class="menu-title">Active Projects</span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="completed-projects.html">
                                                    <span class="menu-title">Completed Projects</span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="canceled-projects.html">
                                                    <span class="menu-title">Cancelled Projects</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <!--<div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                    <span class="menu-link py-3">
                        <span class="menu-title">Work Order</span>
                        <span class="menu-arrow d-lg-none"></span>
                    </span>
                    <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                        <div class="menu-item">
                            <a class="menu-link py-3" href="#">
                                <span class="menu-title">Supplier</span>
                            </a>
                        </div>
                        <div class="menu-item">
                            <a class="menu-link py-3" href="#">
                                <span class="menu-title">Freelancer</span>
                            </a>
                        </div>
                        <div class="menu-item">
                            <a class="menu-link py-3" href="#">
                                <span class="menu-title">AuditFirm</span>
                            </a>
                        </div>

                    </div>
                </div>-->
                                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                                        <span class="menu-link py-3">
                                            <span class="menu-title">Certificates</span>
                                            <span class="menu-arrow d-lg-none"></span>
                                        </span>
                                        <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="add-certificate.html">
                                                    <span class="menu-title">Add Certificate</span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="expired-certificates.html">
                                                    <span class="menu-title">Expired Certificates</span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="certificates.html">
                                                    <span class="menu-title">All Certificates</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="menu-item me-lg-1">
                                        <div class="menu-item">
                                            <a class="menu-link py-3" href="all-compliances.html">
                                                Compliance
                                            </a>
                                        </div>
                                    </div>
                                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                                        <span class="menu-link py-3">
                                            <span class="menu-title">Financials</span>
                                            <span class="menu-arrow d-lg-none"></span>
                                        </span>
                                        <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="Transactions.html">
                                                    <span class="menu-title">Transactions</span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="payments-summary.html">
                                                    <span class="menu-title">Summary</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                                        <span class="menu-link py-3">
                                            <span class="menu-title">Reports</span>
                                            <span class="menu-arrow d-lg-none"></span>
                                        </span>
                                        <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="#">
                                                    <span class="menu-title">Graphical Reports</span>
                                                </a>
                                            </div>
                                            <div class="menu-item">
                                                <a class="menu-link py-3" href="#">
                                                    <span class="menu-title">Data Reports</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <!--end::Menu-->
                                </div>
                                <!--end::Menu wrapper-->
                                <div class="flex-shrink-0 p-4 p-lg-0 me-lg-2">
                                    <div class="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch flex-grow-1" id="#kt_header_menu" data-kt-menu="true">
                                        <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                                            <span class="menu-link py-3">
                                                <span class="menu-title">Quick Links</span>
                                                <span class="menu-arrow d-lg-none"></span>
                                            </span>
                                            <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                                                <div class="menu-item">
                                                    <a class="menu-link py-3" href="create-rfq.html">
                                                        <span class="menu-title">Create RFQ</span>
                                                    </a>
                                                </div>
                                                <div class="menu-item">
                                                    <a class="menu-link py-3" href="active-project-details.html">
                                                        <span class="menu-title">View Quotations</span>
                                                    </a>
                                                </div>
                                                <div class="menu-item">
                                                    <a class="menu-link py-3" href="create-po.html">
                                                        <span class="menu-title">Create PO</span>
                                                    </a>
                                                </div>
                                                <div class="menu-item">
                                                    <a class="menu-link py-3" href="add-compliance.html">
                                                        <span class="menu-title">Add Compliance</span>
                                                    </a>
                                                </div>
                                                <div class="menu-item">
                                                    <a class="menu-link py-3" href="add-certificate.html">
                                                        <span class="menu-title">Add Certificates</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Container-->
                        </div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Toolbar-->
                    <div class="bg-lg py-5 py-lg-5" id="kt_toolbar">
                        <!--begin::Container-->
                        <div id="kt_toolbar_container" class="container-xxl py-5">
                            <!--begin::Row-->
                            <router-view/>
                        </div>
                        <!--end::Toolbar-->
                        <!--begin::Footer-->
                        <div class="footer py-0 d-flex flex-lg-column" id="kt_footer">
                            <!--begin::Container-->
                            <div class="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
                                <!--begin::Copyright-->
                                <div class="text-dark order-2 order-md-1">
                                    <p class="text-gray-800 text-muted  text-hover-primary mb-0">{{ new Date().getFullYear() }}© GlassHUB</p>
                                </div>
                                <!--end::Copyright-->
                                <!--begin::Menu-->
                                <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                                    <li class="menu-item">
                                        <a href="#" class="menu-link px-2">About</a>
                                    </li>
                                    <li class="menu-item">
                                        <a href="#" class="menu-link px-2">Support</a>
                                    </li>
                                    <li class="menu-item">
                                        <a href="#" class="menu-link px-2">Contact</a>
                                    </li>
                                </ul>
                                <!--end::Menu-->
                            </div>
                            <!--end::Container-->
                        </div>
                        <!--end::Footer-->
                    </div>
                </div>
            </div>
            <!--begin::Chat drawer-->
            <div id="kt_drawer_chat" class="bg-body" data-kt-drawer="true" data-kt-drawer-name="chat" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle" data-kt-drawer-close="#kt_drawer_chat_close">
                <!--begin::Messenger-->
                <div class="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
                    <!--begin::Card header-->
                    <div class="card-header pe-5" id="kt_drawer_chat_messenger_header">
                        <!--begin::Title-->
                        <div class="card-title">
                            <!--begin::User-->
                            <div class="d-flex justify-content-center flex-column me-3">
                                <a href="#" class="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">Messages</a>
                            </div>
                            <div class="d-flex justify-content-center flex-column me-3 topviewallmessage_btn">
                                <a href="inbox.html" class="btn btn-xs btn-primary">View All In Inbox</a>
                            </div>
                            <!--end::User-->
                        </div>
                        <!--end::Title-->
                        <div class="card-toolbar">
                            <!--begin::Close-->
                            <div class="btn btn-sm btn-icon btn-active-light-primary" id="kt_drawer_chat_close">
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                                <span class="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                            </div>
                            <!--end::Close-->
                        </div>

                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body" id="kt_drawer_chat_messenger_body">
                        <!--begin::Messages-->
                        <div class="scroll-y me-n5 pe-5" data-kt-element="messages" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer" data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body" data-kt-scroll-offset="0px">
                            <!--begin::Message(in)-->
                            <div class="d-flex justify-content-start mb-10">
                                <!--begin::Wrapper-->
                                <div class="d-flex flex-column align-items-start">
                                    <!--begin::User-->
                                    <div class="d-flex align-items-center mb-2">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/assets/img/avatars/300-1.jpg" />
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Details-->
                                        <div class="ms-3">
                                            <a href="#" class="fs-5 fw-bolder text-gray-900 text-hover-primary me-1">Abraham Jhonson</a>
                                            <span class="text-muted fs-7 mb-1">2 mins ago</span>
                                        </div>
                                        <!--end::Details-->
                                    </div>
                                    <!--end::User-->
                                    <!--begin::Text-->
                                    <div class="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start" data-kt-element="message-text">How likely are you to recommend our company to your friends and family ?</div>
                                    <!--end::Text-->
                                </div>
                                <!--end::Wrapper-->
                            </div>
                            <!--end::Message(in)-->
                            <div class="d-flex justify-content-start mb-10">
                                <!--begin::Wrapper-->
                                <div class="d-flex flex-column align-items-start">
                                    <!--begin::User-->
                                    <div class="d-flex align-items-center mb-2">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/assets/img/avatars/300-2.jpg" />
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Details-->
                                        <div class="ms-3">
                                            <a href="#" class="fs-5 fw-bolder text-gray-900 text-hover-primary me-1">Jaquline Fernandez</a>
                                            <span class="text-muted fs-7 mb-1">25 mins ago</span>
                                        </div>
                                        <!--end::Details-->
                                    </div>
                                    <!--end::User-->
                                    <!--begin::Text-->
                                    <div class="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start" data-kt-element="message-text">How likely are you to recommend our company to your friends and family ?</div>
                                    <!--end::Text-->
                                </div>
                                <!--end::Wrapper-->
                            </div>
                            <!--end::Message(in)-->
                            <div class="d-flex justify-content-start mb-10">
                                <!--begin::Wrapper-->
                                <div class="d-flex flex-column align-items-start">
                                    <!--begin::User-->
                                    <div class="d-flex align-items-center mb-2">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/assets/img/avatars/300-3.jpg" />
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Details-->
                                        <div class="ms-3">
                                            <a href="#" class="fs-5 fw-bolder text-gray-900 text-hover-primary me-1">Birline Tilo</a>
                                            <span class="text-muted fs-7 mb-1">36 mins ago</span>
                                        </div>
                                        <!--end::Details-->
                                    </div>
                                    <!--end::User-->
                                    <!--begin::Text-->
                                    <div class="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start" data-kt-element="message-text">How likely are you to recommend our company to your friends and family ?</div>
                                    <!--end::Text-->
                                </div>
                                <!--end::Wrapper-->
                            </div>
                            <!--end::Message(in)-->
                            <div class="d-flex justify-content-start mb-10">
                                <!--begin::Wrapper-->
                                <div class="d-flex flex-column align-items-start">
                                    <!--begin::User-->
                                    <div class="d-flex align-items-center mb-2">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-35px symbol-circle">
                                            <img alt="Pic" src="/assets/img/avatars/300-4.jpg" />
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Details-->
                                        <div class="ms-3">
                                            <a href="#" class="fs-5 fw-bolder text-gray-900 text-hover-primary me-1">Lora Mike</a>
                                            <span class="text-muted fs-7 mb-1">22 mins ago</span>
                                        </div>
                                        <!--end::Details-->
                                    </div>
                                    <!--end::User-->
                                    <!--begin::Text-->
                                    <div class="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start" data-kt-element="message-text">How likely are you to recommend our company to your friends and family ?</div>
                                    <!--end::Text-->
                                </div>
                                <!--end::Wrapper-->
                            </div>
                            <!--end::Message(in)-->
                        </div>
                        <!--end::Messages-->
                    </div>
                    <!--end::Card body-->
                </div>
                <!--end::Messenger-->
            </div>
        </div>
</template>
<style>
    body {
      /* font-family: "Lato", sans-serif; */
    }

    .overlay {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.9);
      overflow-x: hidden;
      transition: 0.5s;
    }

    .overlay-content {
      position: relative;
      top: 25%;
      width: 100%;
      text-align: center;
    }

    .overlay a {
      padding: 8px;
      text-decoration: none;
      font-size: 36px;
      color: #818181;
      display: block;
      transition: 0.3s;
    }

    .overlay a:hover,
    .overlay a:focus {
      color: #f1f1f1;
    }

    .overlay .closebtn {
      position: absolute;
      top: 80px;
      right: 45px;
      font-size: 60px;
    }

    @media screen and (max-height: 450px) {
      .overlay a {
        font-size: 20px;
      }

      .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
        color: red;
      }
    }
    </style>
<script>
import {mapActions} from 'vuex'
export default {
    data(){
    const COMPANY_LOGO = process.env.VUE_APP_COMPANY_LOGO;
    console.log("Company Logo",COMPANY_LOGO)
    return {
      COMPANY_LOGO
    }
    },
    mounted(){
        document.body.classList.add('header-fixed','header-tablet-and-mobile-fixed', 'toolbar-enabled');
        document.body.classList.remove('bg-body', 'header-extended', 'signupheader');
    },
    unmounted(){
        document.body.classList.remove('header-fixed','header-tablet-and-mobile-fixed', 'toolbar-enabled');
        document.body.classList.add('bg-body', 'header-extended', 'signupheader');
    },
  methods:{
      ...mapActions({
          logout:'auth/logout'
      }),
      dologout(){
          this.logout()
          this.$router.push({name: 'login'})
      }
  }
}
</script>
