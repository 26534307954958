/* eslint-disable vue/return-in-computed-property */
<template>
  <div class="card d-flex flex-row-fluid flex-center">
    <ValidationObserver ref="observer">
      <form class="card-body w-100 w-xl-700px px-9">
        <!--begin::Step 1-->
        <div class="current" data-kt-stepper-element="content">
          <div class="w-100">
            <div class="d-flex justify-content-between">
              <h2 class="fw-bolder align-items-center text-dark">
                Basic Information
              </h2>
            </div>
            <hr />
            <div class="row fv-row mb-10 fv-plugins-icon-container">
              <div class="col-md-6 pb-5">
                <label class="form-label required">FullName</label>
                <ValidationProvider
                  name="Full Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    v-model="signUpForm.Fullname"
                    maxlength="50"
                    class="form-control form-control-lg form-control-solid"
                    :class="errors.length ? 'is-invalid custom-border' : ''"
                  />
                  <span class="invalid-feedback" role="alert">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 pb-5" v-if="Account_type != 2">
                <label class="form-label required">Designation</label>
                <ValidationProvider
                  name="Designation"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                      maxlength="50"
                    v-model="signUpForm.Designation"
                    rules="required"
                    class="form-control form-control-lg form-control-solid"
                    :class="errors.length ? 'is-invalid custom-border' : ''"
                  />
                  <span class="invalid-feedback" role="alert">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 pb-5">
                <label class="form-label required">Email</label>
                <ValidationProvider
                  name="Email"
                  :rules="email_validation"
                  v-slot="{ errors }"
                >
                  <input
                    name="business_email"
                    v-model="signUpForm.Email"
                      maxlength="50"
                    rules="required|email"
                    class="form-control form-control-lg form-control-solid"
                    :class="email_error ? 'is-invalid custom-border' : ''"
                    @keyup="getemail()"
                  />
                   <!-- <span class="invalid-feedback" role="alert">
                    {{ email_error}}
                  </span>  -->
                  <span class="invalid-feedback" role="alert">
                    {{email_error!=null ? email_error : errors[0]}}
                  </span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 pb-5">
                <label class="form-label required">PhoneNumber</label>
                <div>

                  <ValidationProvider
                    name="Phone"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <vue-tel-input
                      name="business_phone"
                      id="phone"
                      class="form-control tel-input-drop form-control-lg form-control-solid" style="display: flex !important"
                      ref="contryCodePhone"
                      v-bind="props"
                      @input="phoneFormattes(signUpForm.Phone)"
                      v-model="signUpForm.Phone"
                      :class="errors.length ? 'is-invalid custom-border' : ''"
                    ></vue-tel-input>
                    <span class="invalid-feedback" role="alert">
                      The Phone field must be at least {{phone_no_length}} digits
                      <!-- {{ errors[0] }} -->
                    </span>
                  </ValidationProvider>
                </div>
              </div>



              <!-- <div class="col-md-6 pb-5" v-if="Account_type==2">
                <label class="form-label">
                Company Name
                </label>

                  <input
                    name=""
                      maxlength="50"
                    placeholder="GlassHub Company"
                    class="form-control form-control-lg form-control-solid"
                    v-model="signUpForm.organization_name"
                  />
              </div> -->

              <div class="col-md-6 pb-5">
                <label class="form-label required">Password</label>
                <ValidationProvider
                  name="Password"
                  :rules="password_validation"
                  vid="confirm"
                  v-slot="{ failedRules,errors,valid }"
                >
                  <input
                    class="form-control form-control-lg form-control-solid"
                    placeholder="*****"
                    type="password"
                    name="password"
                    v-model="signUpForm.Password"
                    rules="required"
                    autocomplete="new-password"
                    :class="errors.length ? 'is-invalid custom-border' : ''"
                  />
                  <span class="mt-2" v-if="valid">{{password_hint}}</span>
                  <span class="invalid-feedback" role="alert">
                    {{failedRules.regex ? error_message : errors[0]}}
                  </span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 pb-5">
                <label class="form-label required">Retype Password</label>
                <ValidationProvider
                  name="Retype Password"
                  rules="required|confirmed:confirm"
                  v-slot="{ errors }"
                >
                  <input
                    class="form-control form-control-lg form-control-solid"
                    rules="required"
                    v-model="signUpForm.CPassword"
                    placeholder="*****"
                    type="password"
                    name="password"
                    autocomplete="off"
                    :class="errors.length ? 'is-invalid custom-border' : ''"
                  />
                  <span class="invalid-feedback" role="alert">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="col-md-12 pb-5">
                <div class="d-flex flex-md-row flex-column">
                  <div class="me-5">
                    <label class="fs-6 fw-bold form-label">
                        Please accept our
                        <a href="javascript:;" @click="TremCondition(0)" class="cursor-pointer" >terms and conditions</a>
                        to proceed?
                    </label>
                  </div>
                  <label
                    class="
                      form-check form-switch form-check-custom form-check-solid
                    "
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      @change="setterm"
                      v-model="signUpForm.Term_accept"
                    />
                  </label>
                </div>
              </div>

              <div class="col-md-12 pb-5">
                  <vue-recaptcha ref="recaptcha" @verify="verifyMethod" :sitekey="recaptcha_key" />
              </div>
              <div class="d-flex flex-stack pt-10">
                <div class="col-md-12">
                  <button
                  :disabled="disabled"
                    type="button"
                    class="btn btn-lg btn-primary"
                    style="float: right"
                    @click="saveBasic()"
                  >
                    Continue
                    <span class="svg-icon svg-icon-4 ms-1 me-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="18"
                          y="13"
                          width="13"
                          height="2"
                          rx="1"
                          transform="rotate(-180 18 13)"
                          fill="black"
                        />
                        <path
                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>


  <div class="modal fade show" v-if="Warning.Description" :style="signUpForm.signup_warning?' display:none;background:rgba(0,0,0,0.5);opacity:0 !important':'display:block;background:rgba(0,0,0,0.5);opacity:1 !important'">
        <div class="modal-dialog modal-md mt-15">
            <div class="modal-content" style="margin-top: 120px;">
                <div class="modal-body">
                  <!-- <div class="icon">
                    <i class="fa-solid fa-exclamation text-warning"></i>
                  </div> -->
                  <!-- <h1 class="text-warning title">Are You Sure</h1> -->
                  <div class="fs-5 text-gray-600 fw-bold" v-html="Warning.Description"></div>
                </div>
                <div class="model-footer d-flex justify-space-between">
                  <button  type="button" @click="move_page()" class="btn btn-lg">Later</button>
                  <button  type="button" class="btn btn-lg btn-primary ml-auto" @click="signUpForm.signup_warning=true" >Continue</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// import { ref, reactive } from '@vue/composition-api'
import { VueRecaptcha } from 'vue-recaptcha';
import {mapActions,mapGetters} from 'vuex';
import * as _ from 'lodash';

export default {
  props: {
    signUpForm: {
      type: Object,
    },
    accoutInfo: {
      type: Object,
    },
    fileData:{
          type:Object
    }
  },
  data() {
    return {
      recaptcha_key: process.env.VUE_APP_RECAPTCHA_KEY ?? '',
      trem_condition: false,
      disabled: false,
      email_validation: "",
      email_error: "",
      password_validation: "",
      error_message: "",
      password_hint: "",
      isVerify: false,
      phone_no_length: 0,
      yourValue: null,
      value: null,
      props: {
        // preferredCountries: ["GB"],
        placeholder: "Enter your phone",
        mode: "international",
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        wrapperClasses: "country-phone-input",
      },
    };
  },



    computed : {
        // eslint-disable-next-line vue/return-in-computed-property
        Account_type() {
            if(this.accoutInfo && this.accoutInfo.Account_type) {
               return this.accoutInfo.Account_type;
            }
        },
        ...mapGetters({
          getCoutryData: "category/getCoutryData",
          getRegistrationSetting:'category/getRegistrationSetting',
          getEmailExist:'category/getEmailExist',
          // accoutInfo:"signup/getAccountType"
          Warning:"signup/getSignupWarning",
        }),
        // getsubSateData1() {
        //     this.setSubCategory1(this.categroy.Scat_id);
        // },
    },
    components: { VueRecaptcha },
    mounted(){
      this.getPasswordHint()
      this.getCategory()
      this.SignUpWarning()
      // this.getemail()
      // setTimeout(()=>{
      //   this.signUpForm.Phone='+966'
      // },100)
    },
    methods:{
        ...mapActions({
            getCategory: "signup/FatchCetegory",
            setRegistrationSetting:'category/setRegistrationSetting',
            setEmailExist: "category/setEmailExist",
        }),
        TremCondition(page_id){
        let account_type=this.accoutInfo.Account_type
        this.$router.push('/terms_and_conditions')
        this.$store.dispatch('signup/FatchTerm',{id:account_type,page_id:page_id})
      },
       SignUpWarning(){
        console.log(this.accoutInfo,'account')
        this.$store.dispatch('signup/FatchSignupWarning',{id:this.accoutInfo.Account_type,page_id:6})
       },
      move_page(){
              localStorage.clear();
              let path = process.env.VUE_APP_GLASSHUB_LIVELINK ?? '';
              window.location.href=`${path}/login`
            },
        checkEmail() {
          const emailAddr = String(this.signUpForm.Email).trim();

          if (emailAddr.length === 0) {
            this.disabled = true;
            this.email_error = 'Please provide a valid email address';
            return;
          }

          this.setEmailExist(this.signUpForm.Email).then((resp) => {
            if (resp.data  && resp.data.emailExist &&  resp.data.emailExist.length > 0) {
                let validationData=resp.data.emailExist[0]
                this.email_error=validationData.email_exist
                this.disabled=true
            }
            else {
              this.disabled=false
              this.email_error=null
            }
          }).catch((errors)=>{
            console.log(errors)
          });

          this.email_validation = 'required|email';
        },
        getemail: _.debounce(
          function () {
            this.checkEmail();
          },
          350
        ),
        getPasswordHint(){
            this.setRegistrationSetting().then((resp) => {
              if(resp.data && resp.data.RegistrationSettingM &&  resp.data.RegistrationSettingM.length > 0){
                  let validationData=resp.data.RegistrationSettingM[0]

                  this.password_hint=validationData.Psw_hint
                  if(validationData.Psw_type==0){
                    this.password_validation='required|min:'+validationData.Psw_length
                  }else if(validationData.Psw_type==1){
                    this.password_validation='required|min:'+validationData.Psw_length+'|regex:^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!@#$%^&*]+)$'
                    this.error_message="Password must contain at least one letter and one number"
                  }else if(validationData.Psw_type==2){
                  this.password_validation='required|min:'+validationData.Psw_length+'|regex:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$'
                    this.error_message="Password must contain at least one special character, one number, one letter and uppercase letter"
                  }
              }
            }).catch((errors)=>{
              console.log(errors)
            })
        },
        async saveBasic(){
         await this.getemail()
          if(this.signUpForm.Phone.length<=this.phone_no_length){
               this.$notify({
                group: 'noti',
                title: 'Error',
                type:'error',
                text:`phone number is required with minimum ${this.phone_no_length} digits length`
              });
              return

          }
           const isValid = await this.$refs.observer.validate();
            if (!isValid) {
              return
            }
            if(this.signUpForm.Email=='')
            {
                this.$notify({
                group: 'noti',
                title: 'Error',
                type:'error',
                text:'Email is empty'
              });
              return
            }
            if(!this.isVerify){
                this.$notify({
                group: 'noti',
                title: 'Error',
                type:'error',
                text:'invalid captcha'
              });
              return
            }
            if(!this.signUpForm.Term_accept){
            this.$notify({
                group: 'noti',
                title: 'Error',
                type:'error',
                text:'Please Accept Terms  and Conditions'
              });
              return
            }
            let data={
                form:this.signUpForm,
                step:2
            }
            this.$emit('saveNext',data)
        },
        verifyMethod(){
            this.isVerify=true
        },
        phoneFormattes(val) {
          const phoneNumberWithPlus = val; // Example phone number
          const targetPhoneCode = parseInt(phoneNumberWithPlus.replace(/\D/g, '')); // Extract phone code and convert to integer
          // Find objects in the array where the phone_code property matches the target phone code
          const matchedObjects = this.getCoutryData.filter(obj => targetPhoneCode.toString().startsWith(obj.phonecode.toString()));
          this.phone_no_length = matchedObjects[0].phoneno_length
          // console.log("Matched Objects:", matchedObjects);
            this.signUpForm.Phone = val.replace(/ /g, "");
            },
            setterm()
            {
              if(this.signUpForm.Term_accept){
                   this.setTermCondition()
              }
            },
    }
  }
</script>

<style scope>
.custom-border {
  border-color: #f1416c !important;
}
.vue-tel-input input{
    background: #f5f8fa !important;
}
.tel-input-drop {
    padding: 0.825rem 0.825rem 0.825rem 8px;
}
.cursor-pointer{
  font-weight: 800;
  text-decoration: underline;
}
a {
    color: #5241a1 !important;
    text-decoration: none;
}
.icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    margin: -61px auto 20px;
    background: #ffffff;
    outline: 1;
    outline: #7B7B75 solid 8px;
    text-align: center;
    line-height: 120px;
}
.icon .fa-exclamation{
     font-size: 50px;
}
.title {
  text-align: center;
  /* margin: 20px; */
}
.model-footer button:nth-child(1){
  background: #9e9e9e;
    color: white;
    flex-grow: 1;
    border-radius: 0px;
    height: 60px;
}

.model-footer button:nth-child(2){
    flex-grow: 1;
    border-radius: 0px;
    height: 60px;
}
</style>
