<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-column-fluid">
      <div
        class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-10"
      >
        <a  class="mb-12 loginlogo">
          <img :src="COMPANY_LOGO" />
        </a>
        <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <form
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
            action="#"
          >
            <div class="text-center mb-10">
              <h1 class="text-dark mb-3">SignUp</h1>
            </div>

            <div class="text-center loginbtn">
              <router-link
                :to="{ name: 'MainSignUp' }"
                class="btn btn-lg btn-primary w-100 mb-5"
                >SignUp with Email</router-link
              >
            </div>
            <div class="text-center text-muted text-uppercase fw-bolder mb-5">
              or
            </div>
            <GoogleLogin
              :params="params"
              :onSuccess="onSuccess"
              :onFailure="onFailure"
              ref="googleLoginRef"
              class="d-none"
            >
            </GoogleLogin>
            <facebook-login
              class="button d-none"
              appId="3264615013770939"
              ref="facebookLoginRef"
              @login="getUserData"
              @logout="onLogout"
              @get-initial-status="getUserData"
            >
            </facebook-login>

            <a :href="'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=7701odxlsasl60&redirect_uri='+LINKEDIN+'&state=foobar&scope=r_liteprofile%20r_emailaddress'">
            <div
              href="#"
              class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
            >
              <img
                alt="Logo"
                src="assets/admin/media/linkedin-logo-login-form-icon.png"
                class="h-20px me-3"
              />{{$t('SignUp')}} with LinkedIn</div>
              </a>

            <div
              class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
              @click="openGoogle()"
            >
              <img
                alt="Logo"
                src="https://preview.keenthemes.com/metronic8/demo2/assets/media/svg/brand-logos/google-icon.svg"
                class="h-20px me-3"
              />{{$t('SignUp')}} with Google
            </div>



            <div
              class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
              @click="openFacebook()"
            >
              <img
                alt="Logo"
                src="https://preview.keenthemes.com/metronic8/demo2/assets/media/svg/brand-logos/facebook-4.svg"
                class="h-20px me-3"
              />{{$t('SignUp')}} with Facebook</div
            >


          </form>
        </div>
      </div>
    </div>
    <!-- <GoogleLogin :params="params" :logoutButton="true">Logout</GoogleLogin> -->
    <div class="container">
      <div class="row footerlogin text-center">
        <div class="col-md-12">
          <p class="text-gray-800 text-hover-primary">{{year}}© {{COMPANY_Name}}</p>
          <ul class="p-0">
            <li><a @click="TremCondition(4)" class="cursor-pointer">About</a></li>
            <li><a @click="TremCondition(5)"  class="cursor-pointer">Support</a></li>
            <li><a @click="TremCondition(3)"  class="cursor-pointer">Contact</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GoogleSignInButton from "vue-google-signin-button-directive";
import GoogleLogin from "vue-google-login";
import facebookLogin from "facebook-login-vuejs";
// import linkDIN from "../../config/linkdine";
import axios from 'axios'
import Api from "@/plugins/apiService.js";
import { mapActions, mapGetters, } from "vuex";
export default {
  data() {
    const COMPANY_Name = process.env.VUE_APP_COMPANY_Name;
    const COMPANY_LOGO = process.env.VUE_APP_COMPANY_LOGO;
    const LINKEDIN = process.env.VUE_APP_LINKDIN;
    const d = new Date();
    let year = d.getFullYear();
    return {
      LINKEDIN,
      COMPANY_Name,
      COMPANY_LOGO,
      year,
      isConnected: false,
      name: '',
      email: '',
      personalID: '',
      picture: '',
      FB: undefined,
      rform: {},
      LinkDinCode:'',
      params: {
        client_id:
          "659906570953-446v9i9u0i5ije6pouh1iqq0dmf9kj7s.apps.googleusercontent.com",
          plugin_name: 'cipher'
      },
    };
  },
  directives: {
    GoogleSignInButton,
  },
  components: { GoogleLogin, facebookLogin },
  computed: {
    ...mapGetters({
      signUpForm: "signup/getSignupData",
      accoutInfo:"signup/getAccountType",
    })
  },
  watch: {
    signUpForm() {
      this.rform = this.signUpForm;
    },
  },
  created() {
    this.rform = this.signUpForm;
    // this.clearDataAllData()
  },
  methods: {
    ...mapActions({
      setDataSignUp: "signup/setDataSignUp",
      // clearDataAllData:"signup/resetCartState",
      //  setAccpuntType: "signup/setAccpuntType",
    }),

 TremCondition(page_id){
  let account_type=5
  this.$router.push('/terms_and_conditions')
  this.$store.dispatch('signup/FatchTerm',{id:account_type,page_id:page_id})
},
    onFailure(data) {
      console.log(data, "failer");
    },
    onSuccess(data) {
      axios.get('https://www.googleapis.com/oauth2/v3/userinfo?access_token='+data.Bc.access_token)
       .then(response=>{
          this.updateSSo("google", response.data.sub,response.data);
       })
    },

    openGoogle() {
      console.log(this.$refs.googleLoginRef);
      this.$refs.googleLoginRef.handleClick();
    },
    openFacebook() {
      this.$refs.facebookLoginRef.buttonClicked();
    },
    getUserData(data){
      if(data?.response){
        axios.get(`https://graph.facebook.com/me?fields=id,name,email&access_token=${data?.response?.authResponse.accessToken}`)
        .then(response=>{
         this.updateSSo("facebook",data?.response?.authResponse?.userID,response.data);
        })
        if(data.response.authResponse) {
          // this.updateSSo("facebook", data?.response?.authResponse.userID);
          this.$router.push({name: 'MainSignUp'})
        }
      }
    },
    onLogin(){
      this.isConnected = true
    },
    onLogout(data){
      console.log(data,"onLogout")
      this.isConnected = false;
    },

    updateSSo(type, token,user) {
      if (type == "google") {
        this.rform.Google_token = token;
        this.rform.Fullname= user.name;
        this.rform.RegistrationD[0].Social_media_url1= user.email;
        this.rform.Email=user.email
        this.$router.push({name: 'MainSignUp'})
      } else if (type == "facebook") {
        this.rform.Facebook_token = token;
        this.rform.Fullname=user.name
        this.rform.Email=user.email
      } else if (type == "linkedin") {
        this.rform.Linkedin_token = token;
        this.rform.Fullname= user.localizedFirstName;
        this.rform.RegistrationD[0].Social_media_url1= user.email;
      }
      // let data = {
      //   form: this.rform,
      // };
      this.setDataSignUp(this.rform);
    },

async linkdineInfo(){
  Api.get('/api/general/linkedin_token?LinkDinCode='+this.LinkDinCode+'&redirect_uri='+this.LINKEDIN)
  .then(response=>{
      let res= response.data
      this.updateSSo("linkedin",res.id,res);
      this.$router.push({name: 'MainSignUp'})
})
.catch(error=>{
  console.log(error)
})
},

    // linkdineOpen() {
    //   linkDIN.OAuth.initialize('il6O6o3wKEL95WSoPB1iJfN2314');
    //   linkDIN.OAuth.popup('linkedin2').then(linkedin => {
    //       console.log('linkedin:',linkedin);
    //       // if(linkedin.access_token) {
    //       //   this.updateSSo("linkedin",linkedin.access_token);
    //       // }
    //       // Prompts 'welcome' message with User's email on successful login
    //       // #me() is a convenient method to retrieve user data without requiring you
    //       // to know which OAuth provider url to call
    //       linkedin.me().then(data => {
    //         console.log('me data:', data);
    //         this.updateSSo("linkedin",data.id,data);
    //         this.$router.push({name: 'signupType'})
    //         // alert('Linkedin says your email is:' + data.email + ".\nView browser 'Console Log' for more details");
    //       })
    //       // Retrieves user data from OAuth provider by using #get() and
    //       // OAuth provider url
    //       linkedin.get('/v2/me').then(data => {
    //         console.log('self data:', data);
    //       })
    //     });
    // }
  },
  mounted(){
       let url_string = window.location.href
       let url = new URL(url_string);
        let code = url.searchParams.get("code");
         this.LinkDinCode=code
        if(this.LinkDinCode)
        {
          this.linkdineInfo()
        }
     console.log(this.accoutInfo,'signup')
  }
};
</script>