<template>
    <div class="card d-none  d-md-flex justify-content-center w-xxl-350px me-4">
        <div class="card-body px-6 px-lg-10 px-xxl-15 py-20">
            <div class="bgi-position-center mb-5 fw-bolder">
                <h2 style="color: #5241a1">{{getStepperTitle}}</h2>
            </div>
         <!-- <p>{{ getStepperArray }}</p> -->
            <div class="stepper-nav">
                <div class="stepper-item" :class="st.id < currentStep ? 'completed' : st.id== currentStep ? 'current' : '' " data-kt-stepper-element="nav" v-for="(st,index) in getStepperArray" :key="index">
                   <template v-if="st.id!=5 || stepFive==true">                  
                    <div class="stepper-line w-40px" v-if="st.id!=4 || stepFive==true"></div>
                    <div class="stepper-icon w-40px h-40px">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">{{st.id}}</span>
                    </div>
                    <div class="stepper-label">
                        <h3 class="stepper-title">{{st.title}}</h3>
                        <div class="stepper-desc fw-bold">
                            {{st.des}}
                        </div>
                    </div>
                     </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
    props:{
    },
    data(){
        return{
           stepFive:true
        }
    },
    computed:{
        ...mapGetters({
            currentStep:"signup/currentStep",
            getStepperTitle:"signup/getStepperTitle",
            getStepperArray:"signup/getStepperArray",
        })
    },
    mounted(){
        if(this.$store.state.signup.user_id)
        {
           this.stepFive=false
        }
      
    }
}
</script>