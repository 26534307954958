require('dotenv').config();

import Vue from 'vue'
import App from './App.vue'
import router from './router';
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false
import store from './store'
import Auth from 'vue-authenticate'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import i18n from './i18n'
Vue.use(VueTelInput);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(Auth, {
  baseUrl: process.env.VUE_APP_REDIRECT_URL, // Your API domain
  providers: {
    github: {
      clientId: '',
      redirectUri: process.env.VUE_APP_REDIRECT_URL+'/sign-up/type' // Your client app URL
    },
    linkedin: {
      name: 'linkedin',
      url: '/auth/linkedin',
      authorizationEndpoint: 'https://www.linkedin.com/oauth/v2/accessToken',
      redirectUri: process.env.VUE_APP_REDIRECT_URL,
      requiredUrlParams: ['state'],
      scope: ['r_emailaddress'],
      scopeDelimiter: ' ',
      state: 'STATE',
      oauthType: '2.0',
      popupOptions: { width: 527, height: 582 },
    },
  }
})
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY ?? '',
    libraries: "places",
  }
})
import Notifications from 'vue-notification'

Vue.use(Notifications)
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
import '@/global/globalComponents.js'
router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = localStorage.getItem('lang')
  }

  // set the current language for i18n.
  i18n.locale = language
  next()
})

// Sentry integration
Sentry.init({
  Vue,
  dsn: "https://818786362f03bc5415e702c1c5be58de@o4507843216015360.ingest.de.sentry.io/4507843780411472",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  //  Capture 100% of the transactions
  tracesSampleRate: 1.0, 
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost", 
    /^https:\/\/apis\.glasshub\.ai/, 
    /^https:\/\/apis\.dev\.glasshub\.ai/,
    /^https:\/\/apis\.staging\.glasshub\.ai/, 
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
