import axios from '@/plugins/apiService.js' 
const state = {
    mainCategory:[],
    subCategory:[],
    subCategory1:[],
    subCategory2:[],
    subCategory3:[],
    subCategory4:[],
    subCategory5:[],
    subCategory6:[],
    registrationSetting:[],
    selected_chips:[],
    cat_chips:[],
    emailExist:[],
    countries:[],
    helperData:{},
    userLoginpassword:""
  };
  
  
  // mutations
  const mutations = {
   SET_MAIN_CATEGORY(state, value) {
          state.mainCategory = value
    },
    SET_SUBCATEGORY(state,value){
        state.subCategory = value
    },
    SET_SUBCATEGORY_1(state,value){
        state.subCategory1 = value
    },
    SET_SUBCATEGORY_2(state,value){
        state.subCategory2 = value
    },
    SET_SUBCATEGORY_3(state,value){
        state.subCategory3 = value
    },
    SET_SUBCATEGORY_4(state,value){
        state.subCategory4 = value
    },
    SET_SUBCATEGORY_5(state,value){
        state.subCategory5 = value
    },
    SET_SUBCATEGORY_6(state,value){
        state.subCategory6 = value
    },
    SET_SELECTED_CHIPS(state,value){
        state.selected_chips = value
    },
    SET_CAT_CHIPS(state,value){
        state.cat_chips = value
    },
    SET_RegistrationSetting(state,value) {
        state.registrationSetting = value
    },
    SET_EmailExist(state,value) {
        state.emailExist = value
    },
    SET_COUNTRIES_DATA(state,value){
        state.countries=value
    },
    SET_HELPER_DATA(state,value){
        state.helperData=value
    },
    SET_LOGIN_PASSWORD(state,value){
        state.userLoginpassword=value
    }
  
  }
  
  // getters
  const getters = {
    getCategory(state){
        return state.mainCategory
    },
    getSubCategory(state){
        return state.subCategory
    },
    getSubCategory1(state){
        return state.subCategory1
    },
    getSubCategory2(state){
        return state.subCategory2
    },
    getSubCategory3(state){
        return state.subCategory3
    },
    getSubCategory4(state){
        return state.subCategory4
    },
    getSubCategory5(state){
        return state.subCategory5
    },
    getSubCategory6(state){
        return state.subCategory6
    },
    getSelectedChip(state){
       return state.selected_chips
    },
    getCatChip(state){
        return state.cat_chips
     },
    getRegistrationSetting(state){
        return state.registrationSetting
    },
    getEmailExist(state){
        return state.emailExist
    },
    getCoutryData(state){
        return state.countries
    },
    getHelperText(state){
        return state.helperData
    },
    getUserPassword(state){
        return state.userLoginpassword
    }

  }
  //actions
  const actions = {
       setMainCatogory({commit},data){
            commit('SET_MAIN_CATEGORY',data)
      },
      setSubCategory({commit},data){
      
            commit('SET_SUBCATEGORY', data)
      
      },
      setSubCategory1({commit},data){
      
            commit('SET_SUBCATEGORY_1', data)
       
      },
      setSubCategory2({commit},data){
      
            commit('SET_SUBCATEGORY_2', data)
       
      },
       setSubCategory3({commit},data){
            commit('SET_SUBCATEGORY_3', data)
      },
       setSubCategory4({commit},data){
      
            commit('SET_SUBCATEGORY_4', data)
      
      },
       setSubCategory5({commit},data){
        
            commit('SET_SUBCATEGORY_5', data)
        
      },
       setSubCategory6({commit},data){
      
            commit('SET_SUBCATEGORY_6', data)
       
      },
      setSelectedChips({commit},data){
        commit('SET_SELECTED_CHIPS',data)
      },
      setCatChips({commit},data){
        commit('SET_CAT_CHIPS',data)
      },
      async setRegistrationSetting({commit}){
        let resp = await axios.get('/api/RegistrationSetting/GetAccountBase/'+ this.state.signup.signaccountType.Account_type)
            commit('SET_RegistrationSetting', resp.data)
        return resp;
      },
      async setEmailExist({commit},email){
        let resp = await axios.get('/api/Signup/EmailExist?email='+ email)
            commit('SET_EmailExist', resp.data)
        return resp;
      },
      async setCountry({commit}){
        let resp = await axios.get('/api/Signup/GetCountry/')
              commit('SET_COUNTRIES_DATA', resp.data)
          return resp;
      },
      async getHelperText({commit},data){
        let resp = await axios.get('/api/HelpingTool/GetToolTip/'+data.page+'/Signup/'+data.type+'/'+null)
        if(resp.data.length > 0){
            commit('SET_HELPER_DATA', resp.data[0])
        }else{
            commit('SET_HELPER_DATA',{})
        }
          return resp;
      },
    setPassword({commit},data){
        commit('SET_LOGIN_PASSWORD',data)
    }

  }
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  