<template>
    <div class="col-xl-2 aside-profile-details">
        <!--begin::Body-->
        <div class="card-body px-6 px-lg-10" style="width: 20rem">
            <div class="company-details">
                <div class="overview mt-8">
                    <br />
                    <h2 style="color: #5241a1" class="fw-bolder">{{getHelperText.H_title}}</h2>
                </div>
                <div
                    class="separator separator-dashed border-gray-300 my-2"
                ></div>
                <div class="d-flex align-items-sm-center mb-2">
                    <p v-html="getHelperText.H_description">
                        
                    </p>
                </div>
            </div>
        </div>
        <!--end::Body-->
    </div>
</template>
<script>
export default {
    props:{
        getHelperText:{
            type:Object
        },
    }
}
</script>
