<template>
    <div class="card d-flex flex-row-fluid flex-center">
        <notifications group="noti" />
        <div class="card-body w-100 w-xl-700px px-9">
            <div>
                <div class="w-100">
                    <div>
                        <h2 class="fw-bolder text-dark">You are done!</h2>
                    </div>
                    <hr />
                    <div class="mb-4">
                        <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                            <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                    <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
                                    <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                                </svg>
                            </span>
                            <div class="d-flex flex-stack flex-grow-1">
                                <div class="fw-bold">
                                    <div class="fs-6 text-gray-700 otp1">
                                        Please click on send otp button to
                                        complete signup
                                    </div>
                                    <div class="fs-6 text-gray-700 otp2">
                                        لاكمال تسجيل الرجاء الضغط على زر ارسال OTP
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <!-- <div class="d-flex flex-stack flex-grow-1">
                            <div class="fw-bold col-md-6">
                                <label class="form-label"
                                    >Phone Verification</label
                                >
                                <input
                                    name="Phone No"
                                    placeholder="35xxxx7614"
                                    v-model="signUpForm.Phone"
                                    disabled
                                    class="form-control form-control-lg form-control-solid mb-4"
                                    size="40"
                                />
                            </div>
                        </div> -->
                        <div class="d-flex flex-stack flex-grow-1">
                            <div class="fw-bold col-md-6">
                                <label class="form-label">Email Verification</label>
                                <input name="email" placeholder="email@yourdomain.com" class="form-control form-control-lg form-control-solid mb-4" size="40"
                                    disabled v-model="signUpForm.Email" />
                            </div>
                            <input id="show1" class="btn btn-lg btn-primary me-16 mt-4" style="margin-right: 10rem !important" type="button" value="Send OTP"
                                @click="sendOtp()" />
                        </div>
                    </div>
                    <div class="menu1" style="" v-if="isShow">
                        <h6 class="mb-10 ml-3" style="color: #5241a1">
                            You can also be completed your signup process by
                            enter OTP in any below field
                        </h6>
                        <ValidationObserver ref="observerbusiness">

                            <!-- <div class="d-flex flex-stack flex-grow-1">
                            <div class="fw-bold col-md-6">
                          <ValidationProvider
                  name="Phone Otp"
                  rules="required"
                  v-slot="{ errors }"
                >
                                <label class="form-label"
                                    >Please enter your Phone OTP</label
                                >
                                <input
                                    name="Phone No"
                                    placeholder="Phone Otp"
                                     :class="errors.length ? 'is-invalid custom-border' : ''"
                                    class="form-control form-control-lg form-control-solid"
                                    v-model="otp.phoneOtp"
                                />
                                <span class="invalid-feedback" role="alert">
                    {{ errors[0] }}
                  </span>
                          </ValidationProvider>
                            </div>
                            <input
                                style="background-color: dimgray"
                                class="btn btn-lg btn-primary me-12 mt-7"
                                type="button"
                                value="Resend SMS "
                                onClick="showHideDiv('divMsg')"
                            /><br /><br />
                        </div> -->

                            <div class="d-flex flex-stack flex-grow-1 mt-4">
                                <div class="fw-bold col-md-6 mb-3">
                                    <ValidationProvider name="Email Otp" rules="required" v-slot="{ errors }">
                                        <label class="form-label">Please enter your Email OTP</label>
                                        <input name="email" placeholder="Email Otp" class="form-control form-control-lg form-control-solid"
                                            :class="errors.length ? 'is-invalid custom-border' : ''" v-model="otp.emailOtp" />
                                        <span class="invalid-feedback" role="alert">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                </div>
                                <input style="background-color: dimgray" class="btn btn-lg btn-primary me-12 mt-5" type="button"
                                    :value="counter > 0 ? `Please Wait...  ${ counter}` : 'Resend Email'" :disabled="counter > 0" @click="sendOtp()" />
                                <br /><br />
                               
                            </div>
                        </ValidationObserver>
                        <div>
                            <input id="show" class="btn btn-lg btn-primary me-4 ml-3 mt-4" type="button" value="verify " @click="verifyOtp()" /><br /><br />
                        </div>
                    </div>

                    <div class="d-flex flex-stack pt-10">
                        <div class="mr-2">
                            <button :disabled="isDisable" type="button" class="btn btn-lg btn-light-primary me-3" @click="goPrev()">
                                <span class="svg-icon svg-icon-4 me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
                                        <path
                                            d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                                            fill="black" />
                                    </svg>
                                </span>
                                Back
                            </button>
                        </div>
                        <div class="menu" v-if="isVarifyShow && (!singup_massage)">
                            <button :disabled="isDisable" class="btn btn-lg btn-primary me-3 text-white" @click="signUpformsave()">
                                <span class="indicator-label">
                                    Completed
                                    <span class="svg-icon svg-icon-3 ms-2 me-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
                                            <path
                                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                fill="black" />
                                        </svg>
                                    </span>
                                    <!--end::Svg Icon-->
                                </span>
                                <span class="indicator-progress">
                                    Please wait...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="menu mb-0" style="" v-if="singup_massage">
                        <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6" style="width: 100%;margin-top: 10px;">
                            <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                    <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
                                    <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                                </svg>
                            </span>
                            <div class="d-flex flex-stack flex-grow-1">
                                <div class="fw-bold w-100">
                                    <h4 class="text-gray-900 fw-bolder">
                                        We need your attention!
                                    </h4>
                                    <div class="fs-6 text-gray-700" v-html="singup_massage">

                                    </div>
                                    <a @click="GotoDashboard()" style="margin-left: auto;cursor: pointer;
    text-align: right;
    display: block;
    font-size: 19px;
    text-decoration: underline;">Login</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        signUpForm: {
            type: Object,
        },
        fileData: {
            type: Object,
        },
        accoutInfo: {
            type: Object,
        },
    },
    data()
    {
        return {
            singup_massage: null,
            email: '',
            counter: 0,
            dashboard_path: process.env.VUE_APP_DASHBOARD_PATH ?? '',
            isDisable: false,
            isShow: false,
            isVarifyShow: false,
            Company_logo: '',
            otp: {
                emailOtp: null,
                phoneOtp: 'null',
            },
        };
    },
    computed: {
        ...mapGetters({
            getCompanyLogo: "signup/getCompanyLogo",
            getCompanyLogoInfo: "signup/getCompanyLogoInfo",
            getAddDocPath: "signup/getAddDocPath",
            getRegDocument_path: "signup/getRegDocument_path",
            getAuthFilePath: 'signup/getAuthFilePath',
            getRegistrationSetting: 'category/getRegistrationSetting'
        }),
    },
    mounted()
    {
        if (this.getCompanyLogoInfo.url)
        {
            this.urlToObject(this.getCompanyLogoInfo.url, this.getCompanyLogoInfo.name)
        }
        // alert(1);
        // console.log(this.signUpForm,"===============")
    },
    methods: {
        ...mapActions({
            singUp: "signup/signUpRegistration",
            sendToOtp: "signup/sendToOtp",
            sendEmail: "signup/SendEmail",
            verifyToOtp: "signup/verifyToOtp",
            resetCartState: "signup/resetCartState",
            setPassword: "category/setPassword",
        }),
        async urlToObject(UserImage, name)
        {
            const response = await fetch(UserImage);
            const blob = await response.blob();
            let file = new File([blob], name, { type: "image/png", lastModified: new Date().getTime(), });
            this.Company_logo = file
        },
        sendOtp()
        {
            // set counter to 60 and start the timer 
            if (this.counter != 0)
            {
                return
            }
            this.counter = 60
            const interval = setInterval(() =>
            {
                this.counter--
                if (this.counter == 0)
                {
                    clearInterval(interval)
                }
            }, 1000)
            this.sendToOtp({
                email_id: this.signUpForm.Email,
                phone: this.signUpForm.Phone,
            })
                .then((response) =>
                {
                    if (response.status == 200)
                    {

                        this.isShow = true;
                        this.$notify({
                            group: "noti",
                            title: "Success",
                            type: "success",
                            text: response.data,
                        });
                    }
                })
                .catch((error) =>
                {
                    if (error.response)
                    {
                        this.$notify({
                            group: "noti",
                            title: "error",
                            type: "error",
                            text: error.response.data,
                        });
                    } else
                    {
                        this.$notify({
                            group: "noti",
                            title: "error",
                            type: "error",
                            text: 'server not Respound',
                        });
                    }

                });
        },


        async verifyOtp()
        {
            const isValid = await this.$refs.observerbusiness.validate();
            if (!isValid)
            {
                return
            }
            this.verifyToOtp({
                emailOtp: this.otp.emailOtp,
                phoneOtp: this.otp.phoneOtp,
                email_id: this.signUpForm.Email,
                phone: this.signUpForm.Phone,
            })
                .then((response) =>
                {
                    if (response.status == 200)
                    {
                        this.isVarifyShow = true;
                        this.$notify({
                            group: "noti",
                            title: "Success",
                            type: "success",
                            text: response.data,
                        });
                    }
                })
                .catch((error) =>
                {
                    if (error.response)
                    {
                        this.$notify({
                            group: "noti",
                            title: "error",
                            type: "error",
                            text: error.response.data,
                        });
                    } else
                    {
                        this.$notify({
                            group: "noti",
                            title: "error",
                            type: "error",
                            text: 'server not Respound',
                        });
                    }
                });
        },
        goPrev()
        {
            this.$emit("gopre", 4);
        },

        signUpformsave()
        {
            this.email = this.signUpForm.Email;
            let Ppassword = this.signUpForm.Password;
            this.isDisable = true;
            // var formData = new FormData();
            // formData.append('Company_logo',this.fileData.Company_logo);
            // formData.append('auth_file_path',this.fileData.auth_file_path);
            // formData.append('RegDocument_path',JSON.stringify(this.fileData.RegDocument_path));
            // let additional_doc=this.fileData.AddDoc_path.map(d => d.file);
            // console.log(additional_doc)
            // formData.append('AddDoc_path',JSON.stringify(additional_doc));
            // formData.append('Company_logo',null);
            // formData.append('auth_file_path',null);
            // formData.append('RegDocument_path',null);
            // formData.append('AddDoc_path',null);
            // formData.append('RegistrationM',JSON.stringify(this.signUpForm));
            let data = {
                otp: this.otp.emailOtp,
                // Company_logo:this.Company_logo,
                Company_logo: this.company_logo_info,
                auth_file_path: this.getAuthFilePath,
                RegDocument_path: this.getRegDocument_path,
                AddDoc_path: this.getAddDocPath,
                RegistrationM: this.signUpForm,
            };
            this.singUp(data)
                .then((response) =>
                {
                    if (response.status == 200)
                    {
                        this.setPassword(Ppassword);
                        this.$notify({
                            group: "noti",
                            title: "Success",
                            type: "success",
                            text: "SignUp successful",
                        });
                        this.sendEmail()
                        if (this.getRegistrationSetting.RegistrationSettingM[0].Account_approval)
                        {
                            this.$store.dispatch('signup/FatchSignupAuth', { id: this.accoutInfo.Account_type, page_id: 8 })
                                .then(res =>
                                {
                                    console.log(res);

                                    this.GotoDashboard()

                                })
                        } else
                        {
                            this.$store.dispatch('signup/FatchSignupAuth', { id: this.accoutInfo.Account_type, page_id: 9 })
                                .then(res =>
                                {
                                    console.log(res);

                                    this.GotoDashboard()


                                })
                        }

                    }
                })
                .catch((errors) =>
                {
                    // console.log(errors.message)

                    this.isDisable = false;

                    if (errors.response)
                    {
                        this.$notify({
                            group: "noti",
                            title: "error",
                            type: "error",
                            text: errors.response.data,
                        });
                    } else
                    {
                        this.$notify({
                            group: "noti",
                            title: "error",
                            type: "error",
                            text: 'server not Respound',
                        });
                    }

                    // this.isDisable = false;
                });
        },
        GotoDashboard()
        {
            // this.$router.push({
            //     name: "login",
            //     query: { email: this.email },
            // });
            localStorage.clear()
            window.location.href = `${this.dashboard_path}/login?email=${this.email}`
        }
    },
};
</script>
<style scoped>
html[lang="en"] .otp1 {
    display: block;
}

html[lang="en"] .otp2 {
    display: none;
}

html[lang="ar"] .otp1 {
    display: none;
}

html[lang="ar"] .otp2 {
    display: block;
}
</style>
