<template>
    <div class="card d-flex flex-row-fluid flex-center">
        <form class="card-body w-100 w-xl-700px px-9">
            <!--begin::Step 1-->
            <ValidationObserver ref="observer" v-slot={}>
                <div class="current" data-kt-stepper-element="content">
                    <div class="w-100">
                        <div>
                            <h2
                                class="fw-bolder d-flex align-items-center text-dark"
                            >
                                Category
                            </h2>
                            <div class="text-muted fw-bold fs-6">
                                Select Business Category
                            </div>
                        </div>
                        <hr />
                        <div class="row fv-row mb-5 fv-plugins-icon-container">
                             <div class="text-muted fw-bold fs-6 mb-5">
                               User can add multiple category :
                            </div>
                            <div class="fv-row mb-2 pb-5 col-md-12">
                                  <label class="form-label required"
                                    >Category</label
                                >
                              
                                <ValidationProvider
                                    name="Category"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <select
                                        name="business_type"
                                        class="form-select form-select-lg form-select-solid"
                                        :class="
                                            errors.length
                                                ? 'is-invalid custom-border'
                                                : ''
                                    "
                                        v-model="Cat_id"
                                        @change="getSubcat()"
                                    >
                                        <option selected value>
                                            Select Category
                                        </option>
                                        <option
                                            v-for="(c, index) in getCat"
                                            :key="index"
                                            :value="c.Cat_id"
                                        >
                                            {{ c.Cat_name }}
                                        </option>
                                    </select>
                                    <span class="invalid-feedback" role="alert">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                              <span v-for="(cat,index) in cat_chips" :key="index">
                               <span  v-if="cat.Cat_name" style="padding: 10px;background: #f5f8fa; display: inline-block;margin-top: 5px; margin-right: 2px;}"  >
                                     <span  @click="deleteCatchip(index)" style="cursor:pointer">X</span>   {{cat.Cat_name}} </span> 
                                </span>
                            </div>
                           <div
                                class="fv-row mb-2 pb-5 col-md-12 category box"
                                v-if="subCatlist.length > 0"
                            >
                                <label class="form-label">Sub-Category</label>
                                <select
                                    name="business_type"
                                    class="form-select form-select-lg form-select-solid"
                                      v-model="subCat_id"
                                       @change="getSubcat1()"
                                >
                                    <option selected value>
                                        Select Subcategory
                                    </option>
                                    <option
                                        v-for="(s, index) in subCatlist"
                                        :key="index"
                                        :value="s.Scat_id"
                                    >
                                        {{ s.Scat_name }}
                                    </option>
                                </select>
                                <span v-for="(cat,index) in selected_chips" :key="index">
                               <span v-if="cat.Scat_name  && !cat.Scat1_name"  style="padding: 10px;background: #f5f8fa; display: inline-block;margin-top: 5px; margin-right: 2px;}"  >
                                     <span   @click="deletechip(index)"   style="cursor:pointer">X</span>{{cat.Scat_name}}</span> 
                                </span>
                            </div>
                                
                            <div
                                class="fv-row mb-2 pb-5 col-md-12 category box"
                                v-if="subCatlist1.length > 0"
                            >
                                <label class="form-label">Sub-Category 1</label>
                                <select
                                    name="business_type"
                                    class="form-select form-select-lg form-select-solid"
                                    v-model="subCat1_id"
                                    @change="getSubcat2()"
                                  
                                >
                                    <option selected value>
                                        Select Subcategory 1
                                    </option>
                                    <option
                                        v-for="(s, index) in subCatlist1"
                                        :key="index"
                                        :value="s.Scat1_id"
                                    >
                                        {{ s.Scat1_name }}
                                    </option>
                                </select>
                                <span v-for="(cat,index) in selected_chips" :key="index">
                               <span v-if="cat.Scat1_name"  style="padding: 10px;background: #f5f8fa; display: inline-block;margin-top: 5px; margin-right: 2px;}"  >
                                     <span  @click="deletechip(index)" style="cursor:pointer">X</span>{{cat.Scat1_name}}</span> 
                                </span>
                            </div>
                         <div
                                class="fv-row mb-2 pb-5 col-md-12 category box"
                                v-if="subCatlist2.length > 0"
                            >
                                <label class="form-label">Sub-Category 2</label>
                                <select
                                    name="business_type"
                                    class="form-select form-select-lg form-select-solid"
                                    @change="getSubcat3()"
                                    v-model="subCat2_id"
                                >
                                    <option selected value>
                                        Select Subcategory 2
                                    </option>
                                    <option
                                        v-for="(s, index) in subCatlist2"
                                        :key="index"
                                        :value="s.Scat2_id"
                                    >
                                        {{ s.Scat2_name }}
                                    </option>
                                </select>
                                 <span v-for="(cat,index) in selected_chips" :key="index">
                               <span v-if="cat.Scat2_name" style="padding: 10px;background: #f5f8fa; display: inline-block;margin-top: 5px; margin-right: 2px;}"  >
                                     <span  @click="deletechip(index)" style="cursor:pointer">X</span> {{cat.Scat2_name}}</span> 
                                </span>
                            </div>

                                <div
                                class="fv-row mb-2 pb-5 col-md-12 category box"
                                v-if="subCatlist3.length > 0"
                            >
                                <label class="form-label">Sub-Category 3</label>
                                <select
                                    name="business_type"
                                    class="form-select form-select-lg form-select-solid"
                                    @change="getSubcat4()"
                                    v-model="subCat3_id"
                                >
                                    <option selected value>
                                        Select Subcategory 3
                                    </option>
                                    <option
                                        v-for="(s, index) in subCatlist3"
                                        :key="index"
                                        :value="s.Scat3_id"
                                    >
                                        {{ s.Scat3_name }}
                                    </option>
                                </select>
                                 <span v-for="(cat,index) in selected_chips" :key="index">
                               <span v-if="cat.Scat3_name" style="padding: 10px;background: #f5f8fa; display: inline-block;margin-top: 5px; margin-right: 2px;}"  >
                                     <span  @click="deletechip(index)" style="cursor:pointer">X</span>{{cat.Scat3_name}}</span> 
                                </span>
                            </div> 

                            <!-- <div class="fv-row mb-2 pb-5 col-md-12 category box" v-if="categroy.Scat3_id">
                                <label class="form-label required"
                                    >Sub-Category 4</label
                                >
                                <select
                                    name="business_type"
                                    class="form-select form-select-lg form-select-solid"
                                    @change="getSubcat5()"
                                    v-model="categroy.Scat4_id"
                                >
                                    <option selected>Select Subcategory 4</option>
                                    <option v-for="(s,index) in getSubCategory4" :key="index" :value="s.Scat4_id">{{s.Scat4_name}}</option>
                                </select>
                                 <span v-for="(cat,index) in all_category" :key="index">
                               <span v-if="cat.Scat4_name!=null" style="padding: 10px;background: #f5f8fa; display: inline-block;margin-top: 5px; margin-right: 2px;}"  >
                                     <span  @click="delteCategroy(index)" style="cursor:pointer">X</span>   {{cat.Scat3_name}} , {{cat.Scat4_name}}</span> 
                                </span>
                            </div> -->

                            <!-- <div class="fv-row mb-2 pb-5 col-md-12 category box" v-if="categroy.Scat4_id">
                                <label class="form-label required"
                                    >Sub-Category 5</label
                                >
                                <select
                                    name="business_type"
                                    class="form-select form-select-lg form-select-solid"
                                    @change="getSubcat6()"
                                    v-model="categroy.Scat5_id"
                                >
                                    <option selected>Select Subcategory 5</option>
                                    <option v-for="(s,index) in getSubCategory5" :key="index" :value="s.Scat5_id">{{s.Scat5_name}}</option>
                                </select>
                                 <span v-for="(cat,index) in all_category" :key="index">
                               <span v-if="cat.Scat5_name!=null" style="padding: 10px;background: #f5f8fa; display: inline-block;margin-top: 5px; margin-right: 2px;}"  >
                                     <span  @click="delteCategroy(index)" style="cursor:pointer">X</span>   {{cat.Scat4_name}} , {{cat.Scat5_name}}</span> 
                                </span>
                            </div> -->

                            <!-- <div class="fv-row mb-2 pb-5 col-md-12 category box" v-if="categroy.Scat5_id">
                                <label class="form-label required"
                                    >Sub-Category 6</label
                                >
                                <select
                                    name="business_type"
                                    class="form-select form-select-lg form-select-solid"
                                    v-model="categroy.Scat6_id"
                                    @change="getSubcat7()"
                                >
                                    <option selected>Select Subcategory 6</option>
                                    <option v-for="(s,index) in getSubCategory6" :key="index" :value="s.Scat6_id">{{s.Scat6_name}}</option>
                                </select>
                                 <span v-for="(cat,index) in all_category" :key="index">
                               <span v-if="cat.Scat6_name!=null" style="padding: 10px;background: #f5f8fa; display: inline-block;margin-top: 5px; margin-right: 2px;}"  >
                                     <span  @click="delteCategroy(index)" style="cursor:pointer">X</span>   {{cat.Scat5_name}} , {{cat.Scat6_name}}</span> 
                                </span>
                            </div> -->
                            <!-- <div class="col-md-12 mb-2 Division box" v-if="showbtn">
                                <button
                                    id="addtable"
                                    type="button"
                                    class="btn btn-lg btn-primary float-end"
                                    v-if="!invalid"
                                    @click="saveCategory"
                                >
                                    Add +
                                </button>
                               
                            </div>
                             <p class="text-danger text-right" v-if="show_message">before go next please click to Add + </p> -->
                            <table
                                class="table table-bordered table-responsive-md table-striped"
                               v-show="all_category.length > 0"
                            >
                                <thead>
                                    <tr>
                                        <th class="fw-bolder">Category</th>
                                        <th class="fw-bolder">Sub-Category</th>
                                        <th class="fw-bolder">Sub-Category1</th>
                                        <th class="fw-bolder">Sub-Category2</th>
                                        <th class="fw-bolder">Sub-Category3</th>
                                        <th class="fw-bolder">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(c, index) in all_category"
                                        :key="index"
                                    >
                                        <td>
                                            {{ c.Cat_name }}
                                        </td>
                                        <td>
                                            {{ c.Scat_name }}
                                        </td>
                                        <td>
                                            {{ c.Scat1_name }}
                                        </td>
                                        <td>
                                            {{ c.Scat2_name }}
                                        </td>
                                        <td>
                                            {{ c.Scat3_name }}
                                        </td>
                                        <td>
                                            <div class="justify-content-end">
                    
                                                <a
                                                     @click="removeCategory(index)"
                                                    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                >
                                        
                                                    <span
                                                        class="svg-icon svg-icon-3"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                                fill="black"
                                                            ></path>
                                                            <path
                                                                opacity="0.5"
                                                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                                fill="black"
                                                            ></path>
                                                            <path
                                                                opacity="0.5"
                                                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                                fill="black"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="d-flex flex-stack pt-10">
                            <div class="mr-2">
                                <button
                                    type="button"
                                    class="btn btn-lg btn-light-primary me-3"
                                    @click="gopre()"
                                >
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x="6"
                                                y="11"
                                                width="13"
                                                height="2"
                                                rx="1"
                                                fill="black"
                                            />
                                            <path
                                                d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </span>
                                    Back
                                </button>
                            </div>
                            <div>
                                <button 
                                    type="button"
                                    class="btn btn-lg btn-primary"
                                    @click="gotoNext()"
                                >
                                    Continue
                                    <span class="svg-icon svg-icon-4 ms-1 me-0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x="18"
                                                y="13"
                                                width="13"
                                                height="2"
                                                rx="1"
                                                transform="rotate(-180 18 13)"
                                                fill="black"
                                            />
                                            <path
                                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
        </form>
    </div>
</template>
<script>
import {mapActions, mapGetters } from "vuex";
export default {
    props: {
        signUpForm: {
            type: Object,
        },
    }, 
    data(){
        return{
            Cat_id:null,
            subCat_id :null,
            subCat1_id :null,
            subCat2_id :null,
            subCat3_id :null,
            subCatlist:[],
            subCatlist1:[],
            subCatlist2:[],
            subCatlist3:[],
            subCatlist4:[],
            subCatlist5:[],
            subCatlist6:[],
            all_category:null,
            cat_chips:[],
            selected_chips:[],
        }
    },

    computed: {
        ...mapActions({
            getCategory: "signup/FatchCetegory",
            // setMainCatogory:"category/setMainCatogory",
            // setMainCatogory1:"category/setMainCatogory1",
            // setSelectedChips:"category/setSelectedChips",
            // setCatChips:"category/setCatChips"
        }),
        ...mapGetters({
            getCat:"signup/getcetegories",
            getSubCategory: "signup/getSubCategories",
            getSubCategory1: "signup/getSubCategories1",
            getSubCategory2: "signup/getSubCategories2",
            getSubCategory3: "signup/getSubCategories3",
            getSubCategory4: "signup/getSubCategories4",
            getSubCategory5: "signup/getSubCategories5",
            getSubCategory6: "signup/getSubCategories6",
            getHelperText: "category/getHelperText",
            getSub:"category/getSubCategory",
            getSub1:"category/getSubCategory1",
            getSelectedChip:"category/getSelectedChip",
            getCatChip:"category/getCatChip"
        }),
    },
    methods:{
        removeCategory(index){
          this.all_category.splice(index,1);
          this.subCat_id=null;
          this.subCat1_id=null;
          this.subCat2_id=null;
          this.subCat3_id=null;
        },
        deletechip(index){
          this.selected_chips.splice(index,1)
        },
        deleteCatchip(index){
        this.cat_chips.splice(index,1)
        },
        //  getUniqueListBy(arr, key) {
        //     return [...new Map(arr.map(item => [item[key], item])).values()]
        //  },
         
        getSubcat(){
         let cat1 =this.getCat.find(({Cat_id}) => Cat_id == this.Cat_id)
         if(this.subCatlist.length == 0)
            this.Addcat()
         if(!this.cat_chips.includes(cat1))
         {
            this.cat_chips.push(cat1)
         }  
        if(this.getSubCategory.find(({Cat_id}) => Cat_id == this.Cat_id))
        {
              this.getSubCategory.filter(sub=>{
                if (sub.Cat_id==this.Cat_id){
                 if(!this.subCatlist.includes(sub))
                   {
                   this.subCatlist.push(sub)
                  }
                }
            })
        }else{
          this.Addcat()
        }
        },

          getSubcat1(){
             let sub = this.getSubCategory.find(({Scat_id}) => Scat_id == this.subCat_id)
            if(!this.selected_chips.includes(sub))
             {
               this.selected_chips.push(sub)
            }
                if(this.getSubCategory1.find(({Scat_id}) => Scat_id == this.subCat_id))
           {
               this.getSubCategory1.filter(sub=>{
                if (sub.Scat_id==this.subCat_id){
                 if(!this.subCatlist1.includes(sub))
                   {
                  
                   this.subCatlist1.push(sub)
                  }
                }
            })
        }else{
          this.AddSub()
        }
        },

           getSubcat2(){
            
             let sub1 = this.getSubCategory1.find(({Scat1_id}) => Scat1_id == this.subCat1_id)
            if(!this.selected_chips.includes(sub1))
              {
               this.selected_chips.push(sub1)
              }
                if(this.getSubCategory2.find(({Scat1_id}) => Scat1_id == this.subCat1_id))
              {
               this.getSubCategory2.filter(sub=>{
                if (sub.Scat1_id==this.subCat1_id){
                 if(!this.subCatlist2.includes(sub))
                   {
                  
                   this.subCatlist2.push(sub)
                  }
                }
            })
        }
        
        else if (this.subCat1_id){
          this.AddSub1()
        }
        },

           getSubcat3(){
            
             let sub2 = this.getSubCategory2.find(({Scat2_id}) => Scat2_id == this.subCat2_id)
            if(!this.selected_chips.includes(sub2))
              {
               this.selected_chips.push(sub2)
              }
                if(this.getSubCategory3.find(({Scat2_id}) => Scat2_id == this.subCat2_id))
              {
               this.getSubCategory3.filter(sub=>{
                if (sub.Scat2_id==this.subCat2_id){
                 if(!this.subCatlist3.includes(sub))
                   {
                   this.subCatlist3.push(sub)
                  }
                }
            })
        }
        
        else if (this.subCat2_id){
          this.AddSub2()
        }
        },

         getSubcat4(){
            
             let sub3 = this.getSubCategory3.find(({Scat3_id}) => Scat3_id == this.subCat3_id)
            if(!this.selected_chips.includes(sub3))
              {
               this.selected_chips.push(sub3)
              }
                if(this.getSubCategory4.find(({Scat3_id}) => Scat3_id == this.subCat3_id))
              {
               this.getSubCategory4.filter(sub=>{
                if (sub.Scat3_id==this.subCat3_id){
                 if(!this.subCatlist4.includes(sub))
                   {
                   this.subCatlist4.push(sub)
                  }
                }
            })
        }
        
        else if (this.subCat3_id){
          this.AddSub3()
        }
        },
           
       Addcat(){
         let cat =this.getCat.find(({Cat_id}) => Cat_id == this.Cat_id)
         const finalData = JSON.parse(JSON.stringify(cat));
            if(!this.all_category.some(({Cat_name})=> Cat_name == finalData.Cat_name))
            {
              this.all_category.push(cat)
            } 
       },
       AddSub(){
        let subcat = '' 
        let cat = '' 
        let all = ''
        subcat = this.getSubCategory.find(({Scat_id}) => Scat_id == this.subCat_id)
        cat  =this.getCat.find(({Cat_id}) => Cat_id ==subcat.Cat_id)
        all =Object.assign(cat,subcat) 
        const finalData = JSON.parse(JSON.stringify(all));
           if(!this.all_category.some(({Scat_name})=> Scat_name == finalData.Scat_name)){
              this.all_category.push(finalData)
           }
       },
         AddSub1(){
        let subcat1= this.getSubCategory1.find(({Scat1_id}) => Scat1_id == this.subCat1_id) 
        let subcat= this.getSubCategory.find(({Scat_id}) => Scat_id == subcat1.Scat_id)
        let cat =this.getCat.find(({Cat_id}) => Cat_id == subcat.Cat_id)
        let all =Object.assign(cat,  subcat , subcat1)  
        const finalData = JSON.parse(JSON.stringify(all));
           if(!this.all_category.some(({Scat1_name})=> Scat1_name == finalData.Scat1_name)){
              this.all_category.push(finalData)
           }
       },

        AddSub2(){
        let subcat2= this.getSubCategory2.find(({Scat2_id}) => Scat2_id == this.subCat2_id) 
        let subcat1= this.getSubCategory1.find(({Scat1_id}) => Scat1_id == subcat2.Scat1_id) 
        let subcat= this.getSubCategory.find(({Scat_id}) => Scat_id == subcat1.Scat_id)
        let cat =this.getCat.find(({Cat_id}) => Cat_id == subcat.Cat_id)
        let all =Object.assign(cat,  subcat , subcat1,subcat2)  
        const finalData = JSON.parse(JSON.stringify(all));
           if(!this.all_category.some(({Scat2_name})=> Scat2_name == finalData.Scat2_name)){
              this.all_category.push(finalData)
           }
       },

         AddSub3(){
        let subcat3= this.getSubCategory3.find(({Scat3_id}) => Scat3_id == this.subCat3_id) 
        let subcat2= this.getSubCategory2.find(({Scat2_id}) => Scat2_id == subcat3.Scat2_id) 
        let subcat1= this.getSubCategory1.find(({Scat1_id}) => Scat1_id == subcat2.Scat1_id) 
        let subcat= this.getSubCategory.find(({Scat_id}) => Scat_id == subcat1.Scat_id)
        let cat =this.getCat.find(({Cat_id}) => Cat_id == subcat.Cat_id)
        let all =Object.assign(cat,subcat,subcat1,subcat2,subcat3) 
        const finalData = JSON.parse(JSON.stringify(all)); 
           if(!this.all_category.some(({Scat3_name})=> Scat3_name == finalData.Scat3_name)){
              this.all_category.push(finalData)
           }
       },

      gopre() {
            this.$emit("gopre", 1);
        },
          gotoNext() {
             if(this.all_category.length === 0){
                 if(this.Cat_id==null)
                 {
                     this.$notify({
                        group: 'noti',
                        title: 'Error',
                        type:'error',
                        text:'Please add categories.'
                    });
                 }
                //  else{
                //         this.$notify({
                //         group: 'noti',
                //         title: 'Error',
                //         type:'error',
                //         text:'Please add your Subcategories.'
                //     });
                //  }
                 
                   return
             }
             this.$store.dispatch('category/setSubCategory',this.subCatlist)
             this.$store.dispatch('category/setSubCategory1',this.subCatlist1)
             this.$store.dispatch('category/setSelectedChips',this.selected_chips)
             this.$store.dispatch('category/setCatChips',this.cat_chips)
            // this.setSubCategory(this.subCatlist)
            //  if(this.all_category.length === 0){
            //      if(this.categroy.Cat_id==null)
            //      {
            //          this.$notify({
            //             group: 'noti',
            //             title: 'Error',
            //             type:'error',
            //             text:'Please add categories.'
            //         });
            //      }else{
            //            this.show_message=true
            //      }
                 
            //        return
            //  }
            //   else if (){
            //       this.show_message==true     
            //  }

            this.signUpForm.RegistrationCategories= this.all_category;
            console.log(this.all_category)
            let data = {
                form: this.signUpForm,
                step: 3,
            };
            this.$emit("saveNext", data);
        },
    },
    created(){

        this.getCategory
        Object.freeze()
    },
    mounted(){
       this.subCatlist=this.getSub
       this.subCatlist1 =this.getSub1
       this.cat_chips=this.getCatChip
       this.selected_chips = this.getSelectedChip
       this.all_category=this.signUpForm.RegistrationCategories;
    }
    // data() {
    //     return {
    //         categroy: {
    //             Cat_id: null,
    //             Cat_name: null,
    //             Cat_code: null,
    //             Scat_id: null,
    //             Scat_name: null,
    //             Scat_code: null,
    //             Scat1_id: null,
    //             Scat1_name: null,
    //             Scat1_code: null,
    //             Scat2_id: null,
    //             Scat2_name: null,
    //             Scat2_code: null,
    //             Scat3_id: null,
    //             Scat3_name: null,
    //             Scat3_code: null,
    //             Scat4_id:null, 
    //             Scat4_code:null, 
    //             Scat4_name:null, 
    //             Scat5_id:null, 
    //             Scat5_code:null, 
    //             Scat5_name:null, 
    //             Scat6_id:null, 
    //             Scat6_code:null, 
    //             Scat6_name:null,
    //             RegD_id:1,
    //         },
    //         showTable: false,
    //         show_message:false,
    //         is_edit: null,
    //         all_category: [],
    //         showbtn:false
    //     };
    // },
    // computed: {
    //     ...mapGetters({
    //         getCategory: "category/getCategory",
    //         getSubCategory: "category/getSubCategory",
    //         getSubCategory1: "category/getSubCategory1",
    //         getSubCategory2: "category/getSubCategory2",
    //         getSubCategory3: "category/getSubCategory3",
    //         getSubCategory4: "category/getSubCategory4",
    //         getSubCategory5: "category/getSubCategory5",
    //         getSubCategory6: "category/getSubCategory6",
    //         getHelperText: "category/getHelperText",
    //     }),
    // },
    // watch:{
    //     // all_category(){
    //     //   console.log('arslan',this.all_category)
    //     // },
    // getSubCategory(){
    //     if(!this.getSubCategory.length){
    //      this.saveCategory()
    //     }else{
    //        this.showbtn=true
    //     }
    // },
    // getSubCategory1(){
    //     if(!this.getSubCategory1.length){
    //      this.saveCategory()
    //      this.showbtn=false
    //     }else{
    //       this.showbtn=true
    //     }
    // },
    //     getSubCategory2(){
    //     if(!this.getSubCategory2.length){
    //      this.saveCategory()
    //     this.showbtn=false
    //     }else{
    //       this.showbtn=true
    //     }
    // },
    //     getSubCategory3(){
    //     if(!this.getSubCategory3.length){
    //      this.saveCategory()
    //      this.showbtn=false
    //     }else{
    //       this.showbtn=true
    //     }
    // },
    //     getSubCategory4(){
    //     if(!this.getSubCategory4.length){
    //      this.saveCategory()
    //      this.showbtn=false
    //     }else{
    //       this.showbtn=true
    //     }
    // },
    //     getSubCategory5(){
    //     if(!this.getSubCategory5.length){
    //      this.saveCategory()
    //    this.showbtn=false
    //     }else{
    //       this.showbtn=true
    //     }
    // },
    //  getSubCategory6(){
    //     if(!this.getSubCategory5.length){
    //      this.saveCategory()
    //      this.showbtn=false
    //     }else{
    //       this.showbtn=true
    //     }
    // }
    // },
    // methods: {
    //     ...mapActions({
    //         setMainCatogory: "category/setMainCatogory",
    //         setSubCategory: "category/setSubCategory",
    //         setSubCategory1: "category/setSubCategory1",
    //         setSubCategory2: "category/setSubCategory2",
    //         setSubCategory3: "category/setSubCategory3",
    //         setSubCategory4: "category/setSubCategory4",
    //         setSubCategory5: "category/setSubCategory5",
    //         setSubCategory6: "category/setSubCategory6",
    //         setgetHelperText: "category/getHelperText",
    //     }),
    //     gopre() {
    //         this.$emit("gopre", 1);
    //     },
    //     getsubcateData() {
    //         this.setSubCategory(this.categroy.Cat_id);
    //     },
    //     getsubSateData1() {
    //         this.setSubCategory1(this.categroy.Scat_id);
    //     },
    //     getsubSateData2() {
    //         this.setSubCategory2(this.categroy.Scat1_id);
    //     },
    //     getsubSateData3() {
    //         this.setSubCategory3(this.categroy.Scat2_id);
    //     },
    //     getsubSateData4() {
    //         this.setSubCategory4(this.categroy.Scat3_id);
    //     },
    //     getsubSateData5() {
    //         this.setSubCategory5(this.categroy.Scat4_id);
    //     },
    //     getsubSateData6() {
    //         this.setSubCategory6(this.categroy.Scat5_id);
    //     },
    //     getSubcat() {
    //         if (this.categroy.Cat_id) {
    //             let data = this.getCategory.find(
    //                 (s) => s.Cat_id == this.categroy.Cat_id
    //             );
    //             if (data) {
    //                 const { Cat_name, Cat_code } = data;
    //                 this.categroy.Cat_name = Cat_name;
    //                 this.categroy.Cat_code = Cat_code;
    //             }
    //             this.getsubcateData();
    //             this.categroy.Scat_id = null;
    //             this.categroy.Scat_name = null;
    //             this.categroy.Scat_code = null; 
    //             this.categroy.Scat1_id = null;
    //             this.categroy.Scat1_name = null;
    //             this.categroy.Scat1_code = null;
    //             this.categroy.Scat2_id = null;
    //             this.categroy.Scat2_name = null;
    //             this.categroy.Scat2_code = null;
    //             this.categroy.Scat3_id = null;
    //             this.categroy.Scat3_name = null;
    //             this.categroy.Scat3_code = null;
    //         } else {
    //             this.clearAllObject();
    //         }
    //     },
    //     getSubcat1() {
    //         if (this.categroy.Scat_id) {
    //             let data = this.getSubCategory.find(
    //                 (s) => s.Scat_id == this.categroy.Scat_id
    //             );
    //             if (data) {
    //                 const { Scat_name, Scat_code } = data;
    //                 this.categroy.Scat_name = Scat_name;
    //                 this.categroy.Scat_code = Scat_code;
    //             }
    //             this.getsubSateData1();
    //             this.categroy.Scat1_id = null;
    //             this.categroy.Scat1_name = null;
    //             this.categroy.Scat1_code = null;
    //             this.categroy.Scat2_id = null;
    //             this.categroy.Scat2_name = null;
    //             this.categroy.Scat2_code = null;
    //             this.categroy.Scat3_id = null;
    //             this.categroy.Scat3_name = null;
    //             this.categroy.Scat3_code = null;
    //         } else {
    //             this.categroy.Scat_id = null;
    //             this.categroy.Scat_name = null;
    //             this.categroy.Scat_code = null;
    //             this.categroy.Scat1_id = null;
    //             this.categroy.Scat1_name = null;
    //             this.categroy.Scat1_code = null;
    //             this.categroy.Scat2_id = null;
    //             this.categroy.Scat2_name = null;
    //             this.categroy.Scat2_code = null;
    //             this.categroy.Scat3_id = null;
    //             this.categroy.Scat3_name = null;
    //             this.categroy.Scat3_code = null;
    //         }
    //     },
    //     getSubcat2() {
    //         if (this.categroy.Scat1_id) {
    //             let data = this.getSubCategory1.find(
    //                 (s) => s.Scat1_id == this.categroy.Scat1_id
    //             );
    //             if (data) {
    //                 const { Scat1_name, Scat1_code } = data;
    //                 this.categroy.Scat1_name = Scat1_name;
    //                 this.categroy.Scat1_code = Scat1_code;
    //             }
    //             this.getsubSateData2();
    //             this.categroy.Scat2_id = null;
    //             this.categroy.Scat2_name = null;
    //             this.categroy.Scat2_code = null;
    //             this.categroy.Scat3_id = null;
    //             this.categroy.Scat3_name = null;
    //             this.categroy.Scat3_code = null;
    //         } else {
    //             this.categroy.Scat1_id = null;
    //             this.categroy.Scat1_name = null;
    //             this.categroy.Scat1_code = null;
    //             this.categroy.Scat2_id = null;
    //             this.categroy.Scat2_name = null;
    //             this.categroy.Scat2_code = null;
    //             this.categroy.Scat3_id = null;
    //             this.categroy.Scat3_name = null;
    //             this.categroy.Scat3_code = null;
    //         }
    //     },
    //     getSubcat3() {
    //         if (this.categroy.Scat2_id) {
    //             let data = this.getSubCategory2.find(
    //                 (s) => s.Scat2_id == this.categroy.Scat2_id
    //             );
    //             if (data) {
    //                 const { Scat2_name, Scat2_code } = data;
    //                 this.categroy.Scat2_name = Scat2_name;
    //                 this.categroy.Scat2_code = Scat2_code;
    //             }
    //             this.getsubSateData3();
    //             this.categroy.Scat3_id = null;
    //             this.categroy.Scat3_name = null;
    //             this.categroy.Scat3_code = null;
    //         } else {
    //             this.categroy.Scat2_id = null;
    //             this.categroy.Scat2_name = null;
    //             this.categroy.Scat2_code = null;
    //             this.categroy.Scat3_id = null;
    //             this.categroy.Scat3_name = null;
    //             this.categroy.Scat3_code = null;
    //         }
    //     },
    //     getSubcat4() {
    //         if (this.categroy.Scat3_id) {
    //             let data = this.getSubCategory3.find(
    //                 (s) => s.Scat3_id == this.categroy.Scat3_id
    //             );
    //             if (data) {
    //                 const { Scat3_name, Scat3_code } = data;
    //                 this.categroy.Scat3_name = Scat3_name;
    //                 this.categroy.Scat3_code = Scat3_code;
    //             }
    //         } else {
    //             this.categroy.Scat3_id = null;
    //             this.categroy.Scat3_name = null;
    //             this.categroy.Scat3_code = null;
    //         }
    //         // this.getsubSateData4()
    //     },
    //     getSubcat5() {
    //         let data = this.getSubCategory4.find(
    //             (s) => s.Scat4_id == this.categroy.Scat4_id
    //         );
    //         if (data) {
    //             const { Scat4_name, Scat4_code } = data;
    //             this.categroy.Scat4_name = Scat4_name;
    //             this.categroy.Scat4_code = Scat4_code;
    //         }
    //         this.getsubSateData5();
    //     },
    //     getSubcat6() {
    //         let data = this.getSubCategory5.find(
    //             (s) => s.Scat5_id == this.categroy.Scat5_id
    //         );
    //         if (data) {
    //             this.categroy.Scat5_name = data.Scat5_name;
    //             this.categroy.Scat5_code = data.Scat5_code;
    //         }
    //         this.getsubSateData6();
    //     },
    //     getSubcat7() {
    //         let data = this.getSubCategory6.find(
    //             (s) => s.Scat6_id == this.categroy.Scat6_id
    //         );
    //         if (data) {
    //             this.categroy.Scat6_name = data.Scat6_name;
    //             this.categroy.Scat6_code = data.Scat6_code;
    //         }
    //     },
    //     clearAllObject() {
    //         this.categroy = {
    //             Cat_id: null,
    //             Cat_name: null,
    //             Cat_code: null,
    //             Scat_id: null,
    //             Scat_name: null,
    //             Scat_code: null,
    //             Scat1_id: null,
    //             Scat1_name: null,
    //             Scat1_code: null,
    //             Scat2_id: null,
    //             Scat2_name: null,
    //             Scat2_code: null,
    //             Scat3_id: null,
    //             Scat3_name: null,
    //             Scat3_code: null,
    //             Scat4_id:null, 
    //             Scat4_code:null, 
    //             Scat4_name:null, 
    //             Scat5_id:null, 
    //             Scat5_code:null, 
    //             Scat5_name:null, 
    //             Scat6_id:null, 
    //             Scat6_code:null, 
    //             Scat6_name:null,
    //         };
    //     },
    //     gotoNext() {
    //          if(this.all_category.length === 0){
    //              if(this.categroy.Cat_id==null)
    //              {
    //                  this.$notify({
    //                     group: 'noti',
    //                     title: 'Error',
    //                     type:'error',
    //                     text:'Please add categories.'
    //                 });
    //              }else{
    //                    this.show_message=true
    //              }
                 
    //                return
    //          }
    //         //   else if (){
    //         //       this.show_message==true     
    //         //  }
    //         this.signUpForm.RegistrationCategories= this.all_category;
    //         let data = {
    //             form: this.signUpForm,
    //             step: 3,
    //         };
    //         this.$emit("saveNext", data);
    //     },
    //     delteCategroy(index) {
    //         this.all_category.splice(index, 1);
    //     },
    //     editCategory(i) {
    //         this.categroy = {
    //             Cat_id: i.Cat_id,
    //             Cat_name: i.Cat_name,
    //             Cat_code: i.Cat_code,
    //             Scat_id: i.Scat_id,
    //             Scat_name: i.Scat_name,
    //             Scat_code: i.Scat_code,
    //             Scat1_id: i.Scat1_id,
    //             Scat1_name: i.Scat1_name,
    //             Scat1_code: i.Scat1_code,
    //             Scat2_id: i.Scat2_id,
    //             Scat2_name: i.Scat2_name,
    //             Scat2_code: i.Scat2_code,
    //             Scat3_id: i.Scat3_id,
    //             Scat3_name: i.Scat3_name,
    //             Scat3_code: i.Scat3_code,
    //             Scat4_id: i.Scat4_id,
    //             Scat4_name: i.Scat4_name,
    //             Scat4_code: i.Scat4_code, 
    //              Scat5_id: i.Scat5_id,
    //             Scat5_name: i.Scat5_name,
    //             Scat5_code: i.Scat5_code, 
    //             Scat6_id: i.Scat6_id,
    //             Scat6_name: i.Scat6_name,
    //             Scat6_code: i.Scat6_code,
    //             uniq_id: i.uniq_id,
    //         };
    //         this.is_edit = i.uniq_id;
    //         if (this.categroy.Cat_id) {
    //             this.setSubCategory(this.categroy.Cat_id).then(() => {
    //                 if (this.categroy.Scat_id)
    //                     this.setSubCategory1(this.categroy.Scat_id).then(() => {
    //                         if (this.categroy.Scat1_id)
    //                             this.setSubCategory2(
    //                                 this.categroy.Scat1_id
    //                             ).then(() => {
    //                                 if (this.categroy.Scat2_id)
    //                                     this.setSubCategory3(
    //                                         this.categroy.Scat2_id
    //                                     ).then(() => {
    //                                         if (this.categroy.Scat3_id)
    //                                             this.setSubCategory4(
    //                                                 this.categroy.Scat3_id
    //                                             );
    //                                     });
    //                             });
    //                     });
    //             });
    //         }
    //     },
    //     saveCategory() {
    //         this.show_message=false
    //         let id = Math.random().toString(36).substr(2, 9);
    //         const catearray = {
    //             uniq_id: id,
    //             Cat_id: this.categroy.Cat_id,
    //             Cat_name: this.categroy.Cat_name,
    //             Cat_code: this.categroy.Cat_code,
    //             Scat_id: this.categroy.Scat_id,
    //             Scat_name: this.categroy.Scat_name,
    //             Scat_code: this.categroy.Scat_code,
    //             Scat1_id: this.categroy.Scat1_id,
    //             Scat1_name: this.categroy.Scat1_name,
    //             Scat1_code: this.categroy.Scat1_code,
    //             Scat2_id: this.categroy.Scat2_id,
    //             Scat2_name: this.categroy.Scat2_name,
    //             Scat2_code: this.categroy.Scat2_code,
    //             Scat3_id: this.categroy.Scat3_id,
    //             Scat3_name: this.categroy.Scat3_name,
    //             Scat3_code: this.categroy.Scat3_code,
    //             Scat4_id: this.categroy.Scat4_id,
    //             Scat4_name: this.categroy.Scat4_name,
    //             Scat4_code: this.categroy.Scat4_code,
    //             Scat5_id: this.categroy.Scat5_id,
    //             Scat5_name: this.categroy.Scat5_name,
    //             Scat5_code: this.categroy.Scat5_code,
    //             Scat6_id: this.categroy.Scat6_id,
    //             Scat6_name: this.categroy.Scat6_name,
    //             Scat6_code: this.categroy.Scat6_code,
    //         };
    //         console.log(catearray,'ccccc')
    //         let results = this.all_category.find(
    //             (o) =>
    //                 o.Cat_id === this.categroy.Cat_id &&
    //                 o.Scat_id === this.categroy.Scat_id &&
    //                 o.Scat1_id === this.categroy.Scat1_id &&
    //                 o.Scat1_id === this.categroy.Scat1_id &&
    //                 o.Scat2_id === this.categroy.Scat2_id &&
    //                 o.Scat3_id === this.categroy.Scat3_id
    //         );
    //         if (!results) {
    //             if (this.is_edit) {
    //                 catearray.uniq_id = this.is_edit;
    //                 let index = this.all_category.findIndex(
    //                     (value) => value.uniq_id === catearray.uniq_id
    //                 );
    //                 this.$set(this.all_category,index,catearray)
    //                 this.is_edit = null;
    //             } else {
    //                 this.all_category.push(catearray);
    //             }
    //         }
    //         this.showTable = true;
    //         // this.signUpForm.RegistrationCategories[0]=this.categroy
    //         //  let data={
    //         //     form:this.signUpForm,
    //         //     step:3
    //         // }
    //         // this.$emit('saveNext',data)
    //     },
    // },
    // created() {
    //     this.all_category=this.signUpForm.RegistrationCategories
    //     if(this.all_category.length > 0){
    //         this.showTable=true
    //     }
    //     this.setMainCatogory()
    // },
};
</script>
