<template>
  <div class="card d-flex flex-row-fluid flex-center">
    <div class="card-body w-100 w-xl-700px px-9">
      <div>
        <ValidationObserver ref="observer">
          <div class="mb-5 mb-xl-8">
            <!--begin::Header-->
            <div class="card-header border-0 px-4">
              <div>
                <h2 class="fw-bolder text-dark">Documents</h2>
                <div class="text-muted fw-bold fs-6">Please upload required documents</div>
              </div>
            </div>
            <hr />
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-2 pb-0 px-4">
              <div class="pb-5 user_doc" v-if="accoutInfo.Account_type == 1 || accoutInfo.Account_type == 3 || accoutInfo.Account_type == 0">
                <h5 class="fw-bolder sub text-dark mb-3 mt-3">Commercial Registration Number</h5>
                <div class="d-flex flex-column align-items-start">
                  <div class="d-flex justify-content-start flex-column flex-grow-1 mb-4 w-100">
                    <input placeholder="Please enter commercial registration number. Ex: 123456789" v-model="com_reg_code"
                      :required="accoutInfo.Account_type == 1" @input="updateCRCode()" class="form-control form-control-solid"
                      :class="accoutInfo.Account_type == 1 ? 'required1' : ''" maxlength="15" />
                  </div>
                  <!--
                <div class="d-flex justify-content-start flex-column flex-grow-1 w-100">
                  <input
                    type="file"
                    class="form-control AddDOU"
                    accept=".pdf"
                    @change="handleFileChangeAd($event, 'com_reg_code', 5500)" />
                  <span class="text-muted fw-bold text-muted d-block pt-1">
                    Please upload a <strong><u>PDF</u></strong> file of your company's Commercial Registration.
                  </span>
                </div>
                -->
                </div>
                <span class="form_label text-danger ml-3 mt-4" v-if="accoutInfo.Account_type == 1 && !com_reg_code">
                  Commercial Registration Number required.
                </span>
              </div>
              <div class="pb-5 user_doc" v-if="accoutInfo.Account_type == 1 || accoutInfo.Account_type == 3 || accoutInfo.Account_type == 0">
                <h5 class="fw-bolder sub text-dark mb-3 mt-3">Vat Number</h5>
                <div class="d-flex flex-column align-items-start">
                  <div class="d-flex justify-content-start flex-column flex-grow-1 mb-4 w-100">
                    <input placeholder="Please enter VAT number. Ex: 123456789" v-model="vat_number" :required="accoutInfo.Account_type == 1"
                      @input="updateVatCode()" class="form-control form-control-solid" :class="accoutInfo.Account_type == 1 ? 'required1' : ''"
                      maxlength="15" />
                  </div>
                </div>
                <span class="form_label text-danger ml-3 mt-4" v-if="accoutInfo.Account_type == 1 && !vat_number">
                  VAT Number required.
                </span>
              </div>

              <h2 class="fw-bolder text-dark sub" v-if="accoutInfo.Account_type == 0">
                {{ $t('Organization') }} Documents
              </h2>
              <h2 class="fw-bolder text-dark sub" v-if="accoutInfo.Account_type == 1 || accoutInfo.Account_type == 3 || accoutInfo.Account_type == 4">
                Company Documents
              </h2>
              <h2 class="fw-bolder text-dark sub" v-if="accoutInfo.Account_type == 2">
                Profession related Documents
              </h2>

              <!--begin::Table container-->
              <div class="table-responsive">
                <!--begin::Table-->
                <table class="table align-middle gs-0 gy-4">
                  <!--begin::Table head-->
                  <thead>
                    <tr class="fw-bolder text-muted bg-light">
                      <th class="ps-4 min-w-200px rounded-start">Document Name</th>
                      <th class="min-w-125px">Status</th>
                      <th class="min-w-200px rounded-end">Upload File</th>
                    </tr>
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  <tbody v-if="getRegistrationSetting && getRegistrationSetting.RegistrationSettingD">
                    <tr v-for="(item, index) in getRegistrationSetting.RegistrationSettingD" :key="item.SettingD_id">
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="d-flex justify-content-start flex-column">
                            <input style="background: none; border: none; color: #0000 !important; font-weight: 700" name=""
                              :placeholder="item.Document_title" class="form-control form-control-solid" readonly />
                          </div>
                        </div>
                      </td>
                      <td>
                        <span v-if="item.Document_type === '1' || item.Document_type === 1" class="badge badge-light-danger fw-bold">Mandatory</span>
                        <span v-else class="badge badge-light-primary fw-bold">Optional</span>
                      </td>
                      <td>
                        <ValidationProvider :name="item.Document_title" rules="required" v-slot="{ errors }" ref="provider">
                          <input type="file" @change="handleFileChange($event, index, item.SettingD_id)" accept="image/*,.pdf,doc,docx"
                            class="form-control RGDOU"
                            :class="[errors.length ? 'is-isDisable custom-border' : '', 'required' + item.Document_type]" />
                          <span class="text-muted fw-bold text-muted d-block">PDF, Doc, Docx, JPG </span>
                          <span class="isDisable-feedback" role="alert">
                            {{ errors[0] }}
                          </span>
                        </ValidationProvider>
                      </td>
                    </tr>
                  </tbody>
                  <!-- <template v-if="getRegistrationSetting && getRegistrationSetting.RegistrationSettingD">
                    <DocumentRow v-for="(item, index) in getRegistrationSetting.RegistrationSettingD" :key="item.SettingD_id" :index="index" :registrationSetting="item" :handleFileChange="handleFileChange" />
                  </template> -->
                  <!--end::Table body-->
                </table>

                <h2 class="fw-bolder text-dark sub mt-6">Additional Documents</h2>

                <table class="table table-hover align-middle gs-0 gy-4">
                  <!--begin::Table head-->
                  <thead>
                    <tr class="fw-bolder table-secondary">
                      <!-- <th class="ps-4 min-w-200px rounded-start">Document Name</th> -->
                      <th class="py-3 px-4">Upload File</th>
                      <th class="py-3 px-4">Action</th>
                    </tr>
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  <!-- <tbody>
                    <tr v-for="(item, index) in signUpForm.AdditionalDoc" :key="index" style="position: relative">
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="d-flex justify-content-start flex-column">
                            <input :placeholder="item.Doc_name" :readonly="item?.readonly == true" v-model="item.Doc_name"
                              :class="`form-control form-control-solid ${item.customClass}`" />
                          </div>
                        </div>
                      </td>
                      <td>
                        <input type="file" :class="`form-control AddDOU ${item.customClass}`"
                          :required="accoutInfo.Account_type == 1 && [0, 1].includes(item.custom_index == 'com_reg_code' ? 0 : index)"
                          :data-validation-message="`Please upload a ${item.Doc_name}`"
                          @change="handleFileChangeAd($event, item.custom_index ? item.custom_index : index, 3600)" accept="image/*,.pdf,doc,docx"
                          :disabled="uploadProgress ? true : false" />
                        <span class="text-muted fw-bold text-muted d-block"> PDF, Doc, Docx, JPG </span>
                        <span class="form_label text-danger ml-3 mt-4 AddDOU-validation"
                          v-if="!Boolean(AddDou[item.custom_index == 'com_reg_code' ? 0 : index]) && accoutInfo.Account_type == 1 && [0, 1].includes(item.custom_index == 'com_reg_code' ? 0 : index)">
                          Document Is required
                        </span>
                      </td>
                      <td>
                        <div v-if="item?.readonly != true">
                          <a href="#" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" v-if="false"> -->
                  <!--begin::Svg Icon | path: icons/duotune/art/art005.svg-->
                  <!-- <span class="svg-icon svg-icon-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3"
                                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                  fill="black" />
                                <path
                                  d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                  fill="black" />
                              </svg>
                            </span> -->
                  <!--end::Svg Icon-->
                  <!-- </a>
                          <a @click="removeAdditionalDoc(index)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                  <!-- <span class="svg-icon svg-icon-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                  d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                  fill="black" />
                                <path opacity="0.5"
                                  d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                  fill="black" />
                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                              </svg>
                            </span> -->
                  <!--end::Svg Icon-->
                  <!-- </a>
                        </div>
                      </td>
                    </tr>
                  </tbody> -->

                  <tbody v-if="getRegistrationSetting && getRegistrationSetting.RegistrationSettingD">
                    <DocumentRow v-for="(item, index) in signUpForm.AdditionalDoc" :key="index" :documentItem="item" :accoutInfo="accoutInfo"
                      :handleFileChangeAd="handleFileChangeAd" :removeAdditionalDoc="removeAdditionalDoc" :handleFileProgress="handleFileProgress"
                      :index="index" :AddDou="AddDou" />
                  </tbody>
                  <!--end::Table body-->
                </table>
                <button @click="addAdditionalDoc('Document', false, '', AddDou.length + 1)" class="btn btn-lg btn-primary w-100 mb-3" type="button"
                  :disabled="uploadProgress ? true : false">
                  <i class="fas fa-plus fa-2x" aria-hidden="true"></i>
                  Add Additional Document
                </button>
                <!--end::Table-->

                <!-- <h3
                class="fw-bolder text-dark mb-3 mt-3 user_doc"
                v-if="account_plan == 1"
              >
                User Documents
              </h3> -->
                <!-- <div class="col-md-12 pb-5 user_doc" v-show="account_plan == 1" >
                <div class="col-md-12">
                  <label class="form-label"
                    >Upload Company's Authorization on Letterhead
                  </label>
                  <a
                    class="image-link mb-5"
                    style="color: sandybrown"
                   href="/assets/img/avatars/titlehead.PNG"
                    download
                    >(Download Sample Draft)</a
                  >
                </div>
                <input
                  type="file"
                  class="form-control form-control-lg form-control-solid authfile"
                  @change="handleUserDocumentChange($event)"
                />
                <span>PDF, Doc, Docx, JPG</span>
                <div class="col-md-12" style="padding-left: 80%">
                  <b>
                    <a
                      class="image-link"
                      :href="path"
                      target="_blank"
                      >Show /
                    </a>
                    <a
                      class="image-link"
                      :href="path"
                      download
                      >Download</a
                    >
                  </b>
                </div>
              </div> -->
              </div>
              <!--end::Table container-->
            </div>

            <div class="mb-5">
              <div class="py-5" v-if="accoutInfo.Account_type != 2">
                <div class="col-md-12">
                  <h5>Are you authorized to operate company account?</h5>
                </div>

                <div class="col-md-4">
                  <input class="" v-model="account_plan" type="radio" name="account_plan" value="1" style="margin-right: 4px" />
                  <span class="fs-6 fw-bold text-muted" style="margin-right: 10px">Yes</span>
                  <input class="" v-model="account_plan" type="radio" name="account_plan" value="0" style="margin-right: 4px" />
                  <span class="fs-6 fw-bold text-muted" style="margin-right: 10px">No</span>
                </div>

                <span class="form_label text-danger ml-3 mt-4" v-if="!account_plan">
                  Selection required.
                </span>
              </div>
            </div>
            <!-- /Additional Fields -->

            <div class="d-flex flex-stack">
              <div class="mr-2">
                <button @click="goPrev()" type="button" class="btn btn-lg btn-light-primary me-3" onclick="stepper.goPrevious();"
                  :disabled="uploadProgress ? true : false">
                  <span class="svg-icon svg-icon-4 me-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
                      <path
                        d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                        fill="black" />
                    </svg>
                  </span>
                  Back
                </button>
              </div>
              <div>
                <button type="button" class="btn btn-lg btn-primary me-3" @click="gotoNext()" :disabled="btn_dis || uploadProgress ? true : false">
                  <span class="indicator-label" v-if="!switch_show_components">
                    Continue
                    <span class="svg-icon svg-icon-3 ms-2 me-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
                        <path
                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                          fill="black" />
                      </svg>
                      <span class="indicator-progress">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </span>
                  </span>
                  <span v-if="switch_show_components"> Signup </span>
                </button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <div class="modal fade show"
      :style="signUpForm.Signup_authority && account_plan == 1 ? 'display:none;background:rgba(0,0,0,0.5);opacity:0 !important' : 'display:block;background:rgba(0,0,0,0.5);opacity:1 !important'"
      v-if="account_plan == 0 && Warning.Description">
      <div class="modal-dialog modal-md mt-15">
        <div class="modal-content" style="margin-top: 120px">
          <div class="modal-body">
            <!-- <div class="icon">
                    <i class="fa-solid fa-exclamation text-warning"></i>
                  </div> -->
            <!-- <h1 class="text-warning title" >Are You Sure</h1> -->
            <div class="fs-5 text-gray-600 fw-bold" v-html="Warning.Description"></div>
          </div>
          <div class="model-footer d-flex justify-space-between">
            <button type="button" class="btn btn-lg" @click="move_page()">
              No
            </button>
            <button type="button" class="btn btn-lg btn-primary ml-auto" @click="SignAuth()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import DocumentRow from './components/documentRow.vue';

export default {
  components: {
    DocumentRow,
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL ?? 'http://localhost:8084',
      path: '',
      btn_dis: false,
      fileSelected: [],
      fileResponse: [],
      selectAddtionalFiles: [],
      switch_show_components: false,
      additionalDocumnets: [
        {
          RegD_id: 1,
          Doc_name: 'document',
        },
      ],
      account_plan: null,
      com_reg_code: null,
      com_reg_code_file: null,
      vat_number_file: null,
      vat_number: null,
      RegistrationDocument: [],
      AddDou: [],
      RegDocument_info: [],
      authFile_info: [],
      uploadProgress: 0,
    };
  },
  props: {
    signUpForm: {
      type: Object,
    },
    accoutInfo: {
      type: Object,
    },
    fileData: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      getRegistrationSetting: 'category/getRegistrationSetting',
      getRegDocument_info: 'signup/getRegDocument_info',
      getAddDocinfo: 'signup/getAddDocinfo',
      getAuthFileInfo: 'signup/getAuthFileInfo',
      Warning: 'signup/getSignupAuth',
    }),
  },
  created() {
    this.getData();
    this.addDefaultDocs();
  },
  watch: {
    getRegistrationSetting() {
      this.fileSelected = [];
      for (let x in this.getRegistrationSetting.RegistrationSettingD) {
        this.urlToObject(this.getRegDocument_info[x]?.url, this.getRegDocument_info[x]?.name).then((res) => {
          if (res.name != 'undefined') {
            let file = res;
            let container = new DataTransfer();
            container.items.add(file);
            document.getElementsByClassName('RGDOU')[x].files = container.files;
            this.handleFileChange(file, x, this.getRegistrationSetting.RegistrationSettingD[x].SettingD_id);
          }
        });
      }
    },
  },
  methods: {
    ...mapActions({
      getData: 'category/setRegistrationSetting',
      setRegistrationSettingDoc: 'signup/setRegistrationSettingDoc',
      setAddDocPath: 'signup/setAddDocPath',
      setRegDocument_path: 'signup/setRegDocument_path',
      setAuthFilePath: 'signup/setAuthFilePath',
      setRegDocument_info: 'signup/setRegDocument_info',
      setAddDocInfo: 'signup/setAddDocInfo',
      setAuthFileInfo: 'signup/setAuthFileInfo',
      setDataSignUp: 'signup/setDataSignUp',
      switchAccount: 'signup/switchAccount',
      resetCartState: 'signup/resetCartState',
    }),
    SignUpWarning() {
      this.$store.dispatch('signup/FatchSignupAuth', { id: this.accoutInfo.Account_type, page_id: 7 });
    },
    move_page() {
      localStorage.clear();
      let path = process.env.VUE_APP_GLASSHUB_LIVELINK;
      window.location.href = `${path}login`;
    },
    SignAuth() {
      this.account_plan = 1;
      this.signUpForm.Signup_authority = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader?.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async urlToObject(UserImage, name) {
      const response = await fetch(UserImage);
      const blob = await response.blob();
      let file = new File([blob], name, { type: 'image/png', lastModified: new Date().getTime() });

      return file;
    },

    async handleFileChange(e, index, id) {
      // console.log('handleFileChange');
      const fileSize = Math.floor(e.target?.files[0].size / 1000);
      if (fileSize > 300) {
        this.$notify({
          group: 'noti',
          title: 'Error',
          type: 'error',
          text: 'file size exceed to 300kb',
          ignoreDuplicates: true,
        });

        e.target.value = '';
        return false;
      }
      // console.log('change');

      // if (fileSize >= 200) {
      //  alert('File size exceeds 200 kb');
      //   // $(file).val(''); //for clearing with Jquery
      // }
      // Proceed further
      // const { valid } = await this.$refs.provider.validate(e);
      const sindex = this.RegistrationDocument.findIndex((d) => {
        return d.SettingD_id === id;
      });

      if (sindex == -1) {
        this.RegistrationDocument.push({
          RegD_id: 1,
          Account_type: this.accoutInfo.Account_type,
          SettingD_id: id,
        });

        if (e.target?.files[0]) {
          this.fileSelected.push(e.target.files[0]);
        } else {
          this.fileSelected.push(e);
        }
      } else {
        this.RegistrationDocument.slice(index, 0, {
          RegD_id: 1,
          Account_type: this.accoutInfo.Account_type,
          SettingD_id: id,
        });

        if (e.target?.files[0]) {
          this.fileSelected[index] = e.target.files[0];
        } else {
          this.fileSelected[index] = e;
        }
      }

      for (let x of this.fileSelected) {
        this.getBase64(x).then((res) => {
          this.RegDocument_info[index] = { name: x.name, url: res };
        });
        // const resFile = await this.triggerUpload('RegDocument_path', x);
        // this.RegDocument_info[index]={name:resFile.filename,url:resFile.fileurl}
        // this.fileResponse[index] = resFile.fileurl;
      }

      this.fileData.RegDocument_path = this.fileSelected;
      this.$emit('fileUpload', this.fileData);
    },
    async triggerUpload(key, file) {
      // console.log('file', file);
      // console.log('index', index);
      try {
        const formData = new FormData();
        formData.append(key, file);
        formData.append('delete', JSON.stringify([this.file?.filename]));
        // const apiUrl = process.env.VUE_APP_API_URL ?? 'http://localhost:8084';
        const response = await axios.post(`${this.apiUrl}/api/Signup/TempDocuments`, formData, {
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (this.uploadProgress >= 100) {
              this.uploadProgress = 0;
            }
            // console.log('this.uploadProgress', this.uploadProgress);
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.uploadStatus = 'Logo uploaded successfully.';
        return response.data.files[key];
      } catch (error) {
        console.error('Error uploading file:', error);
        this.uploadStatus = 'Error uploading logo.';
      }
    },
    handleUserDocumentChange(e) {
      const fileSize = Math.floor(e.target?.files[0].size / 1000);
      if (fileSize > 300) {
        this.$notify({
          group: 'noti',
          title: 'Error',
          type: 'error',
          text: 'file size exceed to 300kb',
          ignoreDuplicates: true,
        });
        e.target.value = '';
        return false;
      }
      // let data={
      //   type:'auth_file',
      //   data:e.target.files[0]
      // }
      let file;
      if (e.target?.files[0]) {
        file = e.target.files[0];
      } else {
        file = e;
      }
      this.getBase64(file).then((res) => {
        this.authFile_info.push({
          name: file.name,
          url: res,
        });
        if (e.target?.files[0]) {
          this.fileData.auth_file_path = e.target.files[0];
          this.path = URL.createObjectURL(e.target.files[0]);
        } else {
          this.fileData.auth_file_path = e;
          this.path = URL.createObjectURL(e);
        }
        this.$emit('fileUpload', this.fileData);
      });
    },

    async handleFileChangeAd(e, index, fsizeLimit = 300, resFile) {
      const fileSize = Math.floor(e.target.files[0].size / 1000);
      const targetFile = {
        index_id: index,
        file: e.target?.files[0] ? e.target.files[0] : e,
        serveFile: resFile,
      };

      // console.log('this.fileData.AddDoc_path', this.fileData.AddDoc_path);

      if (fileSize > fsizeLimit) {
        this.$notify({
          group: 'noti',
          title: 'Error',
          type: 'error',
          text: 'File size exceeds maximum allowed!',
          ignoreDuplicates: true,
        });

        e.target.value = '';
        return false;
      }

      // const resFile = await this.triggerUpload('RegDocument_path', targetFile.file, index);
      // console.log('resFile', resFile);

      if (index === 'com_reg_code') {
        console.info({
          index,
          fsizeLimit,
          e,
          targetFile,
        });

        targetFile.index_id = index = 0;
        this.com_reg_code_file = targetFile;
        // return;
        let removedItem = this.fileData.AddDoc_path.find((_) => _.index_id === index)?.serveFile?.filename;
        if (removedItem)
          this.removeAdditionalDoc(index);
      }
      if (index === 'vat_index') {
        targetFile.index_id = index = 1;
        this.vat_number_file = targetFile;
        let removedItem = this.fileData.AddDoc_path.find((_) => _.index_id === index)?.serveFile?.filename;
        if (removedItem)
          this.removeAdditionalDoc(index);
      }

      const sindex = this.fileData.AddDoc_path.findIndex((d) => {
        return d.index_id === index;
      });

      if (sindex == -1) {
        this.fileData.AddDoc_path.push(targetFile);
      } else {
        this.fileData.AddDoc_path[index] = targetFile;
      }

      // TODO - it is already listed in the AddDoc_path
      for (let x of this.fileData.AddDoc_path) {
        // const resFile = await this.triggerUpload('RegDocument_path', x.file);
        // this.$set(this.AddDou, index, {
        // console.log('resFile', resFile)
        //     name: resFile.filename,
        //     url: resFile.fileurl
        //   })

        //   this.$set(this.AddDou, index, {
        // this.getBase64(x.file).then(res => {
        //     name: x.file.name,
        //     url: res
        //   })
        this.$set(this.AddDou, index, {
          // });
          index,
          name: x.file.name,
          filename: resFile.filename,
          fileurl: resFile.fileurl,
        });
        // console.log('this.AddDou', this.AddDou)
      }
    },

    addAdditionalDoc(name = 'Document', readonly = false, customClass = '', custom_index) {
      let newDoc = {
        RegD_id: 1,
        Doc_name: name === 'Document' ? 'Document ' + custom_index : name,
        readonly,
        customClass,
        custom_index,
      };
      this.signUpForm.AdditionalDoc.push(newDoc);
      this.AddDou[this.AddDou.length] += 1;
    },
    updateCRCode() {
      const regd = { ...(this.signUpForm?.RegistrationD[0] ?? {}) };
      const updated = {
        ...regd,
        CR_number: this.com_reg_code,
      };

      this.signUpForm.RegistrationD[0] = updated;
      this.$store.state.signup.signupForm.RegistrationD[0] = updated;

      // console.info('Updated cr', this.$store.state, this.signUpForm);
    },
    updateVatCode() {
      const regd = { ...(this.signUpForm?.RegistrationD[0] ?? {}) };
      const updated = {
        ...regd,
        vat_number: this.vat_number,
      };

      this.signUpForm.RegistrationD[0] = updated;
      this.$store.state.signup.signupForm.RegistrationD[0] = updated;
    },

    gotoNext() {
      this.addDefaultDocs();

      if (!this.validateData()) {
        return false;
      }

      this.setAddDocInfo(this.AddDou);
      this.setRegDocument_info(this.RegDocument_info);
      this.setAuthFileInfo(this.authFile_info);
      this.setAddDocPath(this.fileData.AddDoc_path);
      // this.signUpForm.AdditionalDoc
      this.setRegDocument_path(this.fileData.RegDocument_path);
      this.setAuthFilePath(this.fileData.auth_file_path);
      this.signUpForm.RegistrationDocument = this.RegistrationDocument;

      let data = {
        form: this.signUpForm,
        step: 5,
      };

      let madantory_doument = document.getElementsByClassName('required1');
      let madantory_doument_check = false;

      for (let md of madantory_doument) {
        console.log();
        if (!md.value) {
          this.$notify({
            group: 'noti',
            title: 'Error',
            type: 'error',
            ignoreDuplicates: true,
            text: md.placeholder && md.placeholder.trim() != '' ? md.placeholder : md.getAttribute('data-validation-message') ?? 'please fill required fields',
          });
          madantory_doument_check = false;
          break;
        } else {
          madantory_doument_check = true;
        }
      }

      if (madantory_doument_check || madantory_doument.length <= 0) {
        let dashboard_path = process.env.VUE_APP_DASHBOARD_PATH ?? '';

        if (this.switch_show_components) {
          this.btn_dis = true;

          this.setDataSignUp(data.form);
          this.switchAccount()
            .then((response) => {
              if (response.status == 200) {
                this.btn_dis = false;
                this.$notify({
                  group: 'noti',
                  title: 'Success',
                  type: 'success',
                  text: 'SignUp successful',
                  ignoreDuplicates: true,
                });
                window.location.href = `${dashboard_path}/dashboard?switchAccout=true`;

                this.resetCartState();
              }
            })
            .catch((errors) => {
              if (errors.response) {
                this.$notify({
                  group: 'noti',
                  title: 'error',
                  type: 'error',
                  text: errors.response.data,
                  ignoreDuplicates: true,
                });
              } else {
                this.$notify({
                  group: 'noti',
                  title: 'error',
                  type: 'error',
                  text: 'server not Respound',
                  ignoreDuplicates: true,
                });
              }
              this.btn_dis = false;
            });
        } else {
          this.$emit('saveNext', data);
        }
      }
    },

    async removeAdditionalDoc(index) {
      let index_id = this.signUpForm.AdditionalDoc[index].custom_index;
      if (index_id === 'com_reg_code') {
        index_id = 0;
      }
      if (index_id === 'vat_index') {
        index_id = 1;
      }
      let removedItem = this.fileData.AddDoc_path.find((_) => _.index_id === index_id)?.serveFile?.filename;
      if (index_id !== 0 || index_id !== 1) {
        this.signUpForm.AdditionalDoc.splice(index, 1);
        
      }
      this.AddDou.splice(index_id, 1);
      this.fileData.AddDoc_path = this.fileData.AddDoc_path.filter((_) => _.index_id !== index_id);
      try {
        // const apiUrl = process.env.VUE_APP_API_URL ?? 'http://localhost:8084';
        await axios.delete(`${this.apiUrl}/api/Signup/TempDocument/` + removedItem, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.uploadStatus = 'File deleted successfully.';
      } catch (error) {
        console.error('Error uploading file:', error);
        this.uploadStatus = 'Error deleting file.';
      }
    },

    async handleFileProgress(progress) {
      this.uploadProgress = progress;
    },

    goPrev() {
      this.addDefaultDocs();
      this.$emit('gopre', 3);
    },
    addDefaultDocs() {
      const autoAdd = () => {
        const len = this.signUpForm.AdditionalDoc.length ?? 0;
        const isApplicable = [1, 3, 0].indexOf(~~this.accoutInfo.Account_type) > -1;
        if (len === 0 && isApplicable === true) {
          let customClass = this.accoutInfo.Account_type == 1 ? 'required1' : '';
          this.addAdditionalDoc('Commercial Registration', true, customClass, 'com_reg_code');
          this.addAdditionalDoc('VAT Certification', true, customClass, 'vat_index');
        }
      };

      setTimeout(() => autoAdd(), 500);
    },
    validateData() {
      if (this.account_plan == null && this.accoutInfo.Account_type != 2) {
        this.$notify({
          group: 'noti',
          title: 'Error',
          type: 'error',
          text: 'Please Confirm whether you are authorized to operate company account or not ?',
          ignoreDuplicates: true,
        });
        return false;
      }

      return true;
    },
  },

  mounted() {
    const regd = { ...(this.signUpForm?.RegistrationD[0] ?? {}) };

    this.com_reg_code = regd?.CR_number ?? '';

    this.vat_number = regd?.vat_number ?? '';

    // console.info('Mounted docs', {regd});

    if (this.signUpForm.Signup_authority == true) {
      this.account_plan = 1;
    }

    this.SignUpWarning();

    this.urlToObject(this.$store.state.signup.company_logo_info.url, this.$store.state.signup.company_logo_info.name).then((res) => {
      this.$store.state.signup.Company_logo = res;
    });

    if (this.$store.state.signup.user_id) {
      this.switch_show_components = true;
    }

    let lengthDOc = this.getRegistrationSetting.RegistrationSettingD.length;
    this.RegDocument_info = new Array(lengthDOc);

    for (let x in this.signUpForm.AdditionalDoc) {
      this.urlToObject(this.getAddDocinfo[x]?.url, this.getAddDocinfo[x]?.name).then((res) => {
        if (res.name != 'undefined') {
          let file = res;
          let container = new DataTransfer();
          container.items.add(file);
          document.getElementsByClassName('AddDOU')[x].files = container.files;
          this.handleFileChangeAd(file, x);
        }
      });
    }

    for (let y in this.getRegDocument_info) {
      this.urlToObject(this.getAuthFileInfo[y]?.url, this.getAuthFileInfo[y]?.name).then((res) => {
        if (res.name != 'undefined') {
          let file = res;
          let container = new DataTransfer();
          container.items.add(file);
          document.getElementsByClassName('authfile')[0].files = container.files;
          this.handleUserDocumentChange(file);
        }
      });
    }

    this.addDefaultDocs();
  },
};
</script>

<style scoped>
.icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
  margin: -61px auto 20px;
  background: #ffffff;
  outline: 1;
  outline: #7b7b75 solid 8px;
  text-align: center;
  line-height: 120px;
}

.icon .fa-exclamation {
  font-size: 50px;
}

.title {
  text-align: center;
}

.model-footer button:nth-child(1) {
  background: #9e9e9e;
  color: white;
  flex-grow: 1;
  border-radius: 0px;
  height: 60px;
}

.model-footer button:nth-child(2) {
  flex-grow: 1;
  border-radius: 0px;
  height: 60px;
}

.fw-bolder.sub {
  font-size: 1.187rem;
}

input[type='file'].form-control {
  padding-top: 6px;
}

/* .AddDOU-validation{
  position: relative;
  left: 10rem;
} */
</style>
./components/DocumentRow.vue
