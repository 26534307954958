<template>
    <div class="content flex-row-fluid" id="kt_content">
    <notifications group="noti"/>
        <div
            class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
            id="kt_create_account_stepper"
        >
        <!-- {{fileUploadData}} -->
            <StepperSide :title="stepperTitle" :stepperContentArray="stepperContentArray"/>
            <component :is="currentComponent" :signUpForm="signUpForm"  :accoutInfo="accoutInfo" @saveNext="saveNext" @gopre="gopre" :fileData="fileUploadData" @fileUpload="fileUpload"/>
            <HelperText :getHelperText="getHelperText"/>
        </div>
    </div>
</template>
<script>
import { mapGetters ,mapActions} from "vuex";
import StepperSide from "@/components/signup/stepperSide.vue";
import Basic from "@/components/signup/stepperContent/basic.vue";
import Category from "@/components/signup/stepperContent/category.vue";
import BusinessInfo from "@/components/signup/stepperContent/businessInfo.vue";
import HelperText from "@/components/signup/stepperContent/helperText.vue";
import Documents from "@/components/signup/stepperContent/documents.vue";
import Completed from "@/components/signup/stepperContent/complete.vue";
import ProfessionInfo from "@/components/signup/stepperContent/proInfo.vue";

export default {
    data() {
        return {
            stepperTitle: "",
            stepperContentArray: [],
            stepper_array: [],
            fileUploadData: {
                Company_logo: "",
                RegDocument_path: [],
                AddDoc_path: [],
                auth_file_path: ""
            },
            currentComponent:"Basic"
        }
    },
    components: {
        StepperSide,
        Basic,
        HelperText,
        Category,
        BusinessInfo,
        Documents,
        Completed,
        ProfessionInfo
    },
     computed:{
        ...mapGetters({
            signUpForm: "signup/getSignupData",
            accoutInfo:"signup/getAccountType",
            currentStep:"signup/currentStep",
            getStepperTitle:"signup/getStepperTitle",
            getStepperArray:"signup/getStepperArray",
            getHelperText:"category/getHelperText",
            getCompanyLogo:"signup/getCompanyLogo"
        }),
    },
    watch:{
        currentStep(){
            let data=this.getStepperArray.find(s => s.id==this.currentStep)
            if(data){
                this.currentComponent=data.component
                let account_type=this.accoutInfo.Account_type
                let page=account_type
                let type=data.title
                let mainObject={
                    page:page,
                    type:type
                }
                this.setgetHelperText(mainObject)

            }
        }
    },
    methods:{
         ...mapActions({
            setDataSignUp: "signup/setDataSignUp",
            setAccpuntType: "signup/setAccpuntType",
            setStepperArray: "signup/setStepperArray",
            setStepperTitle: "signup/setStepperTitle",
            updateCurrentStep: "signup/updateCurrentStep",
            setgetHelperText: "category/getHelperText",
            getSwitchingData:"signup/getSwitchingData"

        }),
        saveNext(data){
            this.setDataSignUp(data.form)
            this.updateCurrentStep(data.step)
        },
        gopre(step){
            this.updateCurrentStep(step)
        },
        fileUpload(data){
            if(data.type=='c_logo'){
                this.fileUploadData.Company_logo=data.data
            }else if(data.type == 'auth_file'){

                this.fileUploadData.auth_file_path=data.data
            }
        }
    },
    mounted(){
        //   let account = JSON.parse(localStorage.getItem('account_type'))
        this.stepperTitle=this.accoutInfo.Account_type==0 ? "Organization Signup" : this.accoutInfo.Account_type==1 ? "Supplier Signup" :this.accoutInfo.Account_type==2 ? " Freelancer Signup" : this.accoutInfo.Account_type==3 ? "Audit Firm Signup" : "Regulator Signup"
            this.stepper_array=[
                {
                    id:1,
                    title:"Basic Info",
                    component:"Basic",
                    des:"Setup Your Account Details"
                },
                {
                    id:2,
                    title:"Category",
                    component:"Category",
                    des:this.accoutInfo.Account_type==2 ? "Select Professional Category" :"Select Business Category"
                },
                {
                    id:3,
                    title:this.accoutInfo.Account_type==2 ? "Profession Info" :"Business Info",
                    component:this.accoutInfo.Account_type==2 ? "ProfessionInfo" :"BusinessInfo",
                    des:this.accoutInfo.Account_type==2 ? "Profession Related Info" :"Company Related Info"
                },
                {
                    id:4,
                    title:"Documents",
                    component:"Documents",
                    des:this.accoutInfo.Account_type==2 ? "Upload Profession related Documents" : "Upload Business Documents"
                },
                {
                    id:5,
                    title:"Completed",
                    component:"Completed",
                    des:"Woah, we are here"
                }
            ]
            this.setStepperArray(this.stepper_array)
            this.setStepperTitle(this.stepperTitle)
            let data=this.getStepperArray.find(s => s.id==this.currentStep)
            if(data){
                this.currentComponent=data.component
                let account_type=this.accoutInfo.Account_type
                // let page = account_type == 0 ? "Organization" : account_type == 1 ? "Supplier" : account_type == 2 ? " Freelancer" : account_type == 3  ? "Audit Firm" : "Regulator"
                let page = account_type
                let type=data.title
                let mainObject={
                    page:page,
                    type:type
                }
                this.setgetHelperText(mainObject)
            }
            // let account_type= JSON.parse(localStorage.getItem('vuex')).signup.signupForm.RegistrationD[0].Account_type
            // this.$store.dispatch('signup/FatchTerm',account_type)
    }
};
</script>
<style>
     @media(max-width:764px)
     {
        #kt_create_account_stepper >div:nth-child(2){
            order:1
        }
        .aside-profile-details{
            order:0
        }
        .aside-profile-details > div{
       width:100% !important;
       margin-bottom:10px;
    }
     }

</style>
