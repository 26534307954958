import Vue from "vue";
import Vuex from "vuex";
import signup from "@/store/modules/signup.js";
import auth from "@/store/modules/login.js";
import category from "@/store/modules/category.js";
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);
let stores = "";
stores = {
    signup,
    category,
    auth
}
/**
 * @desc Inject module in store
 */
const vuexLocal = new VuexPersistence({
    modules: ['signup','auth','category']
    })
  /**
   * @desc Inject module in store
   */
  //  const dataState = createPersistedState({
  //   paths: ['auth','general.theme_dark']
  // })
    
export default new Vuex.Store({
   plugins:[vuexLocal.plugin],
   modules: stores
});

