<template>

    <div class="d-flex flex-column flex-root h-100">
        <!--begin::Page-->
        <div class="page d-flex flex-row flex-column-fluid h-100">
            <!--begin::Wrapper-->
            <div class="wrapper d-flex flex-column flex-row-fluid h-100" id="kt_wrapper">
                <!--begin::Toolbar-->
                <div class="bg-lg py-5 py-lg-5 h-100" id="kt_toolbar">
                    <!--begin::Container-->
                    <div id="kt_toolbar_container" class="container-xxl py-5 h-100">
                        <!--begin::Row-->
                        <div class="row gy-0 gx-10 h-100">
                            <div class="col-xl-12 pd-30 bg-w">


                                <div class="row">
                                    <div class="container">
                                        <!--begin::About card-->
                                        <div class="card">
                                            <div class="col-md-12">
                                                <i @click="$router.go(-1)" style="font-size: 20px; color: #5241a1" class="fa fa-arrow-left cursor-pointer px-5 py-4" ></i>
                                            </div>
                                            <!--begin::Body-->
                                            <div class="card-body p-10 p-lg-15">
                                                <!--begin::Content main-->
                                                <div class="mb-14">
                                                    <!--begin::Heading-->
                                                    <div class="mb-15">
                                                        <!--begin::Title-->
                                                        <!--end::Title-->
                                                        <!--begin::Text-->
                                                    
                                                        <div class="fs-5 text-gray-600 fw-bold" v-html="trem_condition.Description">
                                                        </div>
                                                            <button v-if="trem_condition.Account_id!=5 && trem_condition.Description && trem_condition.Page_Name==0" type="button" class="btn btn-lg btn-primary" @click="accetpTerm()" >Accept</button>

                                                        
                                                        <!-- <div v-if="trem_condition === []" class="fs-5 text-gray-600 fw-bold" > Record Not Available </div> -->
                                                      
                                                        <!--end::Text-->
                                                    </div>
                                                    <!--end::Heading-->
                                                    <!--begin::Body-->
                                                    <!--begin::Table-->
                                                    <div class="mb-14">

                                                    </div>
                                                    <!--end::Table-->

                                                    <!--end::Body-->
                                                </div>
                                                <!--end::Content main-->

                                            </div>
                                            <!--end::Body-->
                                        </div>
                                        <!--end::About card-->
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapActions,mapGetters} from "vuex";
export default {
data(){
    return{
    //    trem_condition:'', 
    //    data:{
    //      Account_type:"",
    //      Legal_id:"",
    //      Legal_pagetype:0,
    //      IsAccept:true,
    //      isActive:false
    //    }
    }
},
computed:{
    ...mapGetters({
       trem_condition:"signup/getTremCondition",
    })
},
methods:{
 ...mapActions({
            // fatchterm: "signup/FatchTerm",
            setTermCondition: "signup/setTermCondition",
}),

accetpTerm(){
    this.$router.push('/sign-up/account')
    this.setTermCondition();
}
},
created(){
//   this.data.Account_type=JSON.parse(localStorage.getItem('vuex')).signup.signupForm.RegistrationD[0].Account_type
//     this.fatchterm(this.data) .then((response) => {
//                     if (response.status == 200) {
//                         this.data.Legal_id =response.data[0].Legal_id;
//                         this.trem_condition=response.data[0].Description                        
//                     }
//     })
},
mounted(){
//   this.trem_condition= this.$store.state.signup.term_condition
}
}
</script>

<style>
</style>