import axios from "@/plugins/apiService.js";
// import router from '@/router'
const getDefaultState = () => {
  return {
    image_path:'',
    image_name:'',
    company_logo_info:{
      name:'',
      url:'',
    },
    user_id:'',
    RegDocument_info:null,
    auth_file_Info:null,
    AddDoc_info:null,
    Company_logo:{},
    auth_file_path:null,
    RegDocument_path:null,
    AddDoc_path:null,
    signupForm: {
      Fullname: null,
      Designation: null,
      Email:null,
      Phone: null,
      Password: null,
      Term_accept: false,
      signup_warning:false,
      Signup_authority:false,
      Legal_id:null,
      Legal_pagetype:0,
      IsAccept:true,
      Isactive: true,
      Google_token: null,
      Facebook_token: null,
      Linkedin_token: null,
      remember_token: null,
      RegistrationD: [
        {
          RegM_id: 1,
          Account_type: 0,
          Account_type_name: "Orgnisation",
          CR_number: null,
          Company_name: null,
          Company_size: null,
          Start_date: null,
          Country_id: null,
          Head_office_address: null,
          Head_office_lat: 0,
          Head_office_long: 0,
          Website_url: null,
          Social_media_url1: null,
          Social_media_url2: null,
          Social_media_url3: null,
          Social_media_url4: null,
          Social_media_url5: null,
          Social_media_url6: null,
          Account_status: 1,
          Authorized: false,
          Phone_otp: null,
          Email_otp: null,
          RegD_Role: 0,
          bank_name:null,
          bank_customer_name:null,
          bank_iban:null

        },
      ],
      RegistrationCategories: [],
      Office_Branches: [],
      RegistrationDocument: [],
      AdditionalDoc: [],
    },
    signaccountType: null,
    current_step: 1,
    stepper_array: [],
    stepperTitle: "",
    selected_country_array: [],
    term_condition:'',
    SignupWarning:'',
    Signup_authority:'',
    cetegories:[],
    SubCategories:[],
    SubCategories1:[],
    SubCategories2:[],
    SubCategories3:[],
    SubCategories4:[],
    SubCategories5:[],
    SubCategories6:[],
  };
};
const state = getDefaultState();

// mutations
const mutations = {
  UPDATE_FORM(state, value) {
    state.signupForm = value;
  },
  UPDATE_ACCOUNT_TYPE(state, value) {
    state.signaccountType = value;
  },
  SET_CURRENT_STEP(state, value) {
    state.current_step = value;
  },
  SET_STEPPER_TITLE(state, value) {
    state.stepperTitle = value;
  },
  SET_STEPPER_ARRAY(state, value) {
    state.stepper_array = value;
  },
  SET_Registration_Document(state, value) {
    state.signupForm.RegistrationDocument = value;
  },
  SET_Additional_Document(state, value) {
    state.signupForm.AdditionalDoc = value;
  },
  SET_COUNTRY_ARRAY(state, value) {
    state.selected_country_array = value;
  },
  SET_COMPANY_LOGO(state, value) {
    state.Company_logo = value;
  },
  SET_COMPANY_LOGO_INFO(state,value){
     state.company_logo_info.name= value.name,
     state.company_logo_info.url=value.url
  },
  SET_ADD_DOC_PATH(state, value) {
    state.AddDoc_path = value;
  },
  SET_ADD_DOC_INFO(state, value) {
    state.AddDoc_info = value;
  },
  SET_REG_DOCUMENT_PATH(state, value) {
    state.RegDocument_path = value;
  },
  SET_REG_DOCUMENT_INFO(state, value) {
    state.RegDocument_info = value;
  },
  SET_AUTH_FILE_PATH(state,value){
    state.auth_file_path=value
  },
  SET_AUTH_FILE_INFO(state,value){
    state.auth_file_Info=value
  },

  SET_CETEGORY(state,value){
    state.cetegories=value.Categories
    state.SubCategories=value.SubCategories
    state.SubCategories1=value.SubCategories1
    state.SubCategories2=value.SubCategories2
    state.SubCategories3=value.SubCategories3
    state.SubCategories4=value.SubCategories4
    state.SubCategories5=value.SubCategories5
    state.SubCategories6=value.SubCategories6
  },

  SET_TERM_CONDITION(state){
    // state.signupForm.Legal_id=value.Legal_id;
    // state.signupForm.Legal_pagetype=value.Legal_pagetype;
    // state.signupForm.IsAccept=value.IsAccept;
    state.signupForm.Term_accept=true
  },
  // SET_COMPANY_LOGO(state, value) {
  //   state.Company_logo = value;
  // },

  resetState (state) {
    Object.assign(state, getDefaultState())
  }
};

// getters
const getters = {
  getTremCondition(state){
    return state.term_condition
  },
  getSignupWarning(state){
    return state.SignupWarning
  },
  getSignupAuth(state){
    return state.Signup_authority
  },
  getSignupData(state) {
    return state.signupForm;
  },
  getAccountType(state) {
    return state.signaccountType;
  },
  currentStep(state) {
    return state.current_step;
  },
  getStepperTitle(state) {
    return state.stepperTitle;
  },
  getStepperArray(state) {
    return state.stepper_array;
  },
  getSelectedCountry(state) {
    return state.selected_country_array;
  },
  getCompanyLogo(state) {
    return state.Company_logo;
  },
  getCompanyLogoInfo(state){
    return state.company_logo_info
  },
  getAddDocPath(state) {
    return state.AddDoc_path;
  },
  getAddDocinfo(state) {
    return state.AddDoc_info;
  },
  getRegDocument_path(state){
   return state.RegDocument_path;
  },
  getRegDocument_info(state){
    return state.RegDocument_info;
   },
  getAuthFilePath(state){
    return state.auth_file_path
  },
  getAuthFileInfo(state){
    return state.auth_file_Info
  },

  getcetegories(state){
    return state.cetegories
  },
  getSubCategories(state){
    return state.SubCategories
  },
  getSubCategories1(state){
    return state.SubCategories1
  },
  getSubCategories2(state){
    return state.SubCategories2
  },
  getSubCategories3(state){
    return state.SubCategories3
  },
  getSubCategories4(state){
    return state.SubCategories4
  },
  getSubCategories5(state){
    return state.SubCategories5
  },
  getSubCategories6(state){
    return state.SubCategories6
  }

};
//actions
const actions = {
  setDataSignUp({ commit }, data) {
    commit("UPDATE_FORM", data);
  },
  setAccpuntType({ commit }, data) {
    commit("UPDATE_ACCOUNT_TYPE", data);
  },
  updateCurrentStep({ commit }, data) {
    commit("SET_CURRENT_STEP", data);
  },
  setStepperTitle({ commit }, data) {
    commit("SET_STEPPER_TITLE", data);
  },
  setStepperArray({ commit }, data) {
    commit("SET_STEPPER_ARRAY", data);
  },
  setRegistrationSettingDoc({ commit }, data) {
    commit("SET_Registration_Document", data.organization_doc);
    commit("SET_Additional_Document", data.additional_doc);
  },
  setSelectedCountry({ commit }, data) {
    commit("SET_COUNTRY_ARRAY", data);
  },
  updateCompanyLogo({ commit }, data){
    commit("SET_COMPANY_LOGO", data);
  },
  setCompanyLogoInfo({commit},data){
     commit("SET_COMPANY_LOGO_INFO",data)
  },
  setAddDocPath({commit},data){
    commit("SET_ADD_DOC_PATH",data)
  },
  setAddDocInfo({commit},data){
    commit("SET_ADD_DOC_INFO",data)
  },
  setRegDocument_path({commit},data){
    commit("SET_REG_DOCUMENT_PATH",data)
  },
  setRegDocument_info({commit},data){
    commit("SET_REG_DOCUMENT_INFO",data)
  },
  setAuthFilePath({commit},data){
    commit("SET_AUTH_FILE_PATH",data)
  },
  setAuthFileInfo({commit},data){
    commit("SET_AUTH_FILE_INFO",data)
  },
  setTermCondition({commit},data){
    commit("SET_TERM_CONDITION",data)
  },
  resetCartState ({ commit }) {
    commit('resetState')
  },
  // eslint-disable-next-line no-empty-pattern
  async signUpRegistration({},data) {
    let formData = new FormData()
    // formData.append('Company_logo',data.Company_logo)
    formData.append('auth_file_path',data.auth_file_path)
   for(let x in data.RegDocument_path)
   {
    formData.append('RegDocument_path', data.RegDocument_path[x])
   }
    // for(let x of data.AddDoc_path){
    // formData.append('AddDoc_path',x.file)
    // }
    formData.append('data', JSON.stringify(data))
      let resp = await axios.post("/api/Signup/Insert", formData);
      return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async sendToOtp({},data) {
      let resp = await axios.get(`/api/otp/SendOTP/${data.email_id}/${data.phone}`);
      return resp;
},
  // eslint-disable-next-line no-empty-pattern
  async verifyToOtp({},data) {
    let resp = await axios.get(`/api/otp/VerifyOTP/${data.email_id}/${data.emailOtp}/${data.phone}/${data.phoneOtp}`);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async FatchTerm({state},data) {
    let resp = await axios.get(`/api/userlegal/latest-legal?Account_id=${data?.id}&Page_Name=${data?.page_id}`);
    state.signupForm.Legal_id=resp.data[0]?.Legal_id;
    state.term_condition = resp.data[0] || []
    state.signupForm.IsAccept=resp.data[0]?.IsAccept

  },
  async FatchSignupWarning({state},data) {
    let resp = await axios.get(`/api/userlegal/latest-legal?Account_id=${data?.id}&Page_Name=${data?.page_id}`);
    if(resp.data[0]){
      state.SignupWarning = resp.data[0]
    }else{
    let res =  await axios.get(`/api/userlegal/latest-legal?Account_id=5&Page_Name=${data?.page_id}`);
    state.SignupWarning = res.data[0] || []
    }

  },
  async FatchSignupAuth({state},data) {

    let resp = await axios.get(`/api/userlegal/latest-legal?Account_id=${data?.id}&Page_Name=${data?.page_id}`);
    if(data?.page_id==8 || data?.page_id==9){
      if(resp.data[0])
      {
        return resp.data
      }else{
       let res =  await axios.get(`/api/userlegal/latest-legal?Account_id=5&Page_Name=${data?.page_id}`);
       if(res.data[0])
       {
        return res.data
       }else{
        return []
       }
      }
    }else{
      if(resp.data[0]){
        state.Signup_authority= resp.data[0]
      }else{
        let res =  await axios.get(`/api/userlegal/latest-legal?Account_id=5&Page_Name=${data?.page_id}`);
        state.Signup_authority= res.data[0]  || []
    }
  }
  },
  // eslint-disable-next-line no-empty-pattern
  async FatchCetegory({commit}) {
    let resp = await axios.get(`/api/CategoriesDef/GetFullList`);
    commit('SET_CETEGORY',resp.data)
  },

async SendEmail ({state}){
  await axios.get(`/api/Forgot_logs/templateemail/${state.signupForm.Email}/null/null/${state.signupForm.RegistrationD[0].Company_name}/${state.signupForm.RegistrationD[0].Account_type}/Sign up Email/signup/1/${state.signupForm.Fullname}/${state.signupForm.RegistrationD[0].Account_type_name}/null/null`)
},

async switchAccount({state}){
   let Company_logo =state.Company_logo
   let auth_file_path = state.auth_file_path;
   let RegDocument_path = state.RegDocument_path;
   let AddDoc_path = state.AddDoc_path;
   let RegistrationM={User_id:state.user_id,...state.signupForm}
   let data ={RegistrationM:RegistrationM}

   let formData = new FormData()
    formData.append('Company_logo',Company_logo)
    formData.append('auth_file_path',auth_file_path)
   for(let x in RegDocument_path)
   {
    formData.append('RegDocument_path',RegDocument_path[x])
   }
    for(let x of AddDoc_path){
    formData.append('AddDoc_path',x.file)
    }
    formData.append('data', JSON.stringify(data))
    let resp = await axios.post("/api/Signup/Insertwith", formData);
    return resp;
},

async GetBasicData({state},payload){
   try{
      const resp = await axios.get('/api/signup/DataForNewSignup?User_id='+payload)
       let data = resp.data.RegistrationD[0]
       let set_data = state.signupForm.RegistrationD[0]
      set_data.CR_number=data.CR_number
      set_data.Company_name=data.Company_name
      set_data.Company_size=data.Company_size
      set_data.Start_date=data.Start_date.substring(0,10)
      set_data.Country_id=data.Country_id.split(",")
      set_data.Head_office_address=data.Head_office_address
      set_data.Head_office_address=data.Head_office_address
      set_data.Head_office_lat=data.Head_office_lat
      set_data.Head_office_long=data.Head_office_long
      // set_data.Website_url=data.Website_url
      set_data.Social_media_url1=data.Social_media_url1=='null' && data.Social_media_url1!='undefined' ?data.Social_media_url1:null
      set_data.Social_media_url2=data.Social_media_url2=='null' && data.Social_media_url2!='undefined' ?data.Social_media_url2:null
      set_data.Social_media_url3=data.Social_media_url3=='null' && data.Social_media_url3!='undefined' ?data.Social_media_url3:null
      set_data.Social_media_url4=data.Social_media_url4=='null' && data.Social_media_url4!='undefined' ?data.Social_media_url4:null
      set_data.Social_media_url5=data.Social_media_url5=='null' && data.Social_media_url5!='undefined' ?data.Social_media_url5:null
      set_data.Social_media_url6=data.Social_media_url6=='null' && data.Social_media_url6!='undefined' ?data.Social_media_url6:null
       for(let x of resp.data.Office_Branches)
       {
        state.signupForm.Office_Branches.push({
          Office_address:x.Office_address,
          Office_lat:x.Office_lat,
          Office_long:x.Office_long,
          markers: [],
          center: { lat: 10, lng: 10 },
        })
       }
       let logo =process.env.VUE_APP_API_URL+'/public/images/'+ data.Company_logo
       state.image_path = logo
       state.image_name = data.Company_logo
   }catch(error){
     console.log(error)
   }
}



};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
