<template>
    <div class="row gy-0 gx-10">
        <div class="col-xl-3 aside-profile-details">
            <div class="card">
                <!--begin::Body-->
                <div class="card-body pt-5">
                    <div class="profilelogo">
                        <img src="/assets/img/organizations/media/profile_logo/logo.png" />
                    </div>
                    <div class="company-details">
                        <div class="overview">
                            <br>
                            <p class="text-center text-muted mb-2">Account Type: Organization</p>
                            <h4>Organization Solutions LLC</h4>
                            <div class="star_ratings_of_company">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <p class="display-inline">5 (24 Reviews)</p>
                            </div>
                        </div>
                        <div class="separator separator-dashed border-gray-300 my-8"></div>
                        <div class="d-flex align-items-sm-center mb-2">
                            <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div class="flex-grow-1 me-2">
                                    <p><i class="fa fa-map-marker-alt" aria-hidden="true"></i><span class="pl-5">From</span></p>

                                </div>
                                <p>United States</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-sm-center mb-2">
                            <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div class="flex-grow-1 me-2">
                                    <p><i class="fas fa-user"></i><span class="pl-5">Partner Since</span></p>

                                </div>
                                <p>Nov 2020</p>
                            </div>
                        </div>
                        <div class="separator separator-dashed border-gray-300 my-8"></div>
                        <div class="d-flex align-items-sm-center mb-2">
                            <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div class="flex-grow-1 me-2">
                                    <p>Completed Projects</p>

                                </div>
                                <span class="badge badge-light fw-bolder my-2">+500</span>
                            </div>
                        </div>
                        <div class="d-flex align-items-sm-center mb-2">
                            <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div class="flex-grow-1 me-2">
                                    <p>Active Projects</p>

                                </div>
                                <span class="badge badge-light fw-bolder my-2">10</span>
                            </div>
                        </div>
                        <div class="separator separator-dashed border-gray-300 my-8"></div>
                        <div class="d-flex align-items-sm-center mb-2">
                            <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div class="flex-grow-1 me-2">
                                    <p>Registered Suppliers</p>

                                </div>
                                <span class="badge badge-light fw-bolder my-2">30</span>
                            </div>
                        </div>
                        <div class="d-flex align-items-sm-center mb-2">
                            <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div class="flex-grow-1 me-2">
                                    <p>Registered Audit Firms</p>

                                </div>
                                <span class="badge badge-light fw-bolder my-2">23</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Body-->
            </div>
        </div>
        <div class="col-xl-9">
            <div class="row g-5 g-xl-8 organization-dashboard-firstrow">
                <div class="col-xl-4">
                    <!--begin::Statistics Widget 5-->
                    <a href="#" class="card bg-primary hoverable card-xl-stretch mb-xl-8">
                        <!--begin::Body-->

                        <div class="card-body">
                            <img src="/assets/admin/media/organizations-dashboard-supplier-icon.png" />
                            <h1 class="mt-5">30</h1>
                            <div class="text-white fw-bolder fs-2 mb-2">Registered Suppliers</div>
                        </div>
                        <!--end::Body-->
                    </a>
                    <!--end::Statistics Widget 5-->
                </div>
                <div class="col-xl-4">
                    <!--begin::Statistics Widget 5-->
                    <a href="#" class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8">
                        <!--begin::Body-->
                        <div class="card-body">
                            <img src="/assets/admin/media/organizations-dashboard-auditfirm-icon.png" />
                            <h1 class="mt-5">23</h1>
                            <div class="text-white fw-bolder fs-2 mb-2">Registered Audit Firms</div>
                        </div>
                        <!--end::Body-->
                    </a>
                    <!--end::Statistics Widget 5-->
                </div>
                <div class="col-xl-4">



                    <div id="myNav" class="overlay">
                        <a href="javascript:void(0)" class="closebtn " onclick="closeNav()">&times;</a>
                        <div class="overlay-content" >
                            <div class="card-body mb-xl-8" style="background-image: url('admin/assets/img/Picture1.png'); padding: 19rem 1rem">

                            </div>
                        </div>
                    </div>
                    <a href="#" class="card  hoverable card-xl-stretch mb-xl-8">
                        <div class="card-body " style="background-image: url('admin/assets/img/Picture1.png'); background-size:cover; background-repeat:no-repeat">
                            <!--span style="font-size:30px;cursor:pointer" onclick="openNav()">&#9776; open</span>-->
                        </div>
                        <button class="btn btn-transparent" onclick="openNav()"> <i class="fa fa-external-link" aria-hidden="true"></i> </button>
                    </a>
                </div>
                <div class="row gy-5 g-xl-8 dashboard-stats-2ndrow">
                    <div class="col-xl-4">
                        <!--begin::List Widget 4-->
                        <div class="card card-xl-stretch mb-xl-8">
                            <!--begin::Beader-->
                            <div class="card-header border-0 py-5">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="card-label fw-bolder fs-3 mb-1">You may also signup as</span>
                                </h3>
                            </div>
                            <!--end::Header-->
                            <!--begin::Body-->
                            <div class="card-body p-10 d-flex flex-column">
                                <!--begin::Stats-->
                                <div class="mb-0">
                                    <!--begin:Option-->
                                    <label class="d-flex flex-stack mb-5 cursor-pointer">
                                        <!--begin:Label-->
                                        <span class="d-flex align-items-center me-2">
                                            <!--begin::Icon-->
                                            <span class="symbol symbol-50px me-6">
                                                <span class="form-check form-check-custom form-check-solid ">
                                                    <input class="form-check-input" type="radio" name="account_plan" value="1" />
                                                </span>
                                            </span>
                                            <!--end::Icon-->
                                            <!--begin::Description-->
                                            <span class="d-flex flex-column">
                                                <span class="fw-bolder text-gray-800 text-hover-primary fs-5">Audit Firm</span>

                                            </span>
                                            <!--end:Description-->
                                        </span>
                                        <!--end:Label-->
                                        <!--begin:Input-->
                                        <!--end:Input-->
                                    </label>
                                    <!--end::Option-->
                                    <!--begin:Option-->
                                    <label class="d-flex flex-stack mb-5 cursor-pointer">
                                        <!--begin:Label-->
                                        <span class="d-flex align-items-center me-2">
                                            <!--begin::Icon-->
                                            <span class="symbol symbol-50px me-6">
                                                <span class="form-check form-check-custom form-check-solid ">
                                                    <input class="form-check-input" type="radio" name="account_plan" value="1" />
                                                </span>
                                            </span>
                                            <!--end::Icon-->
                                            <!--begin::Description-->
                                            <span class="d-flex flex-column">
                                                <span class="fw-bolder text-gray-800 text-hover-primary fs-5">Supplier</span>

                                            </span>
                                            <!--end:Description-->
                                        </span>
                                        <!--end:Label-->
                                        <!--begin:Input-->
                                        <!--end:Input-->
                                    </label>
                                    <label class="d-flex flex-stack mb-5 cursor-pointer">
                                        <!--begin:Label-->
                                        <span class="d-flex align-items-center me-2">
                                            <!--begin::Icon-->
                                            <span class="symbol symbol-50px me-6">
                                                <span class="form-check form-check-custom form-check-solid ">
                                                    <input class="form-check-input" type="radio" name="account_plan" value="1" />
                                                </span>
                                            </span>
                                            <!--end::Icon-->
                                            <!--begin::Description-->
                                            <span class="d-flex flex-column">
                                                <span class="fw-bolder text-gray-800 text-hover-primary fs-5">Freelancer</span>

                                            </span>
                                            <!--end:Description-->
                                        </span>
                                        <!--end:Label-->
                                        <!--begin:Input-->
                                        <!--end:Input-->
                                    </label>
                                    <!--end::Option-->
                                    <!--begin:Option-->
                                    <label class="d-flex flex-stack mb-5 cursor-pointer">
                                        <!--begin:Label-->
                                        <span class="d-flex align-items-center me-2">
                                            <!--begin::Icon-->
                                            <span class="symbol symbol-50px me-6">
                                                <span class="form-check form-check-custom form-check-solid ">
                                                    <input class="form-check-input" type="radio" name="account_plan" value="1" />
                                                </span>
                                            </span>
                                            <!--end::Icon-->
                                            <!--begin::Description-->
                                            <span class="d-flex flex-column">
                                                <span class="fw-bolder text-gray-800 text-hover-primary fs-5">Regulator</span>

                                            </span>
                                            <!--end:Description-->
                                        </span>
                                        <!--end:Label-->
                                        <!--begin:Input-->
                                        <!--end:Input-->
                                    </label>
                                    <!--end::Option-->
                                </div>
                            </div>
                            <div class="col-md-6 text-right mb-5">
                                <a href="signup2.html" class="btn btn-primary">Get Started</a>
                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::List Widget 4-->
                    </div>
                    <!--begin::Col-->
                    <div class="col-xl-4">
                        <!--begin::List Widget 2-->
                        <div class="card card-xl-stretch mb-xl-8">
                            <!--begin::Header-->
                            <div class="card-header border-0">
                                <h3 class="card-title fw-bolder text-dark">Active Projects Status </h3>
                            </div>
                            <!--end::Header-->
                            <!--begin::Body-->
                            <div class="card-body pt-2">
                                <!--begin::Item-->
                                <div class="d-flex align-items-center mb-7">
                                    <!--begin::Text-->
                                    <div class="flex-grow-1">
                                        <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">Project 001</a>
                                        <span class="text-muted d-block fw-bold"><b>Supplier:</b> BritoMint</span>
                                    </div>
                                    <div class="flex-grow-1 text-right">
                                        <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">40%</a>
                                        <span class="text-muted d-block fw-bold">Completed</span>
                                    </div>
                                    <!--end::Text-->
                                </div>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <div class="d-flex align-items-center mb-7">
                                    <!--begin::Text-->
                                    <div class="flex-grow-1">
                                        <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">Project 002</a>
                                        <span class="text-muted d-block fw-bold"><b>Supplier:</b> MicroAli</span>
                                    </div>
                                    <div class="flex-grow-1 text-right">
                                        <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">70%</a>
                                        <span class="text-muted d-block fw-bold">Completed</span>
                                    </div>
                                    <!--end::Text-->
                                </div>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <div class="d-flex align-items-center mb-7">
                                    <!--begin::Text-->
                                    <div class="flex-grow-1">
                                        <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">Project 003</a>
                                        <span class="text-muted d-block fw-bold"><b>Supplier:</b> Design786</span>
                                    </div>
                                    <div class="flex-grow-1 text-right">
                                        <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">90%</a>
                                        <span class="text-muted d-block fw-bold">Completed</span>
                                    </div>
                                    <!--end::Text-->
                                </div>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <div class="d-flex align-items-center mb-7">
                                    <!--begin::Text-->
                                    <div class="flex-grow-1">
                                        <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">Project 004</a>
                                        <span class="text-muted d-block fw-bold"><b>Supplier:</b> AceTech</span>
                                    </div>
                                    <div class="flex-grow-1 text-right">
                                        <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">70%</a>
                                        <span class="text-muted d-block fw-bold">Completed</span>
                                    </div>
                                    <!--end::Text-->
                                </div>
                                <!--end::Item-->
                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::List Widget 2-->
                    </div>
                    <!--end::Col-->
                    <!--begin::Col-->
                    <div class="col-xl-4">
                        <!--begin::List Widget 2-->
                        <div class="card card-xl-stretch mb-xl-8 Gettingstartedlinksdashboard">
                            <!--begin::Header-->
                            <div class="card-header border-0">
                                <h3 class="card-title fw-bolder text-dark">Getting Started</h3>
                            </div>
                            <!--end::Header-->
                            <!--begin::Body-->
                            <div class="card-body pt-2">
                                <!--begin::Item-->
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-grow-1">
                                        <a href="add-compliance.html">Set Registration Criteria for Supplier</a>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-grow-1">
                                        <a href="add-compliance.html">Set Registration Criteria for Freelancer</a>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-grow-1">
                                        <a href="add-compliance.html">Set Registration Criteria for Audit Firm</a>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-grow-1">
                                        <a href="new_requests_suppliers.html">Register New Supplier</a>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-grow-1">
                                        <a href="new_requests_freelancer.html">Register New Freelancer</a>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-grow-1">
                                        <a href="new_requests_auditfirms.html">Register New Audit Firm </a>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-grow-1">
                                        <a href="add-certificate.html">Save Certificates</a>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-grow-1">
                                        <a href="payments-summary.html">Financial Summary</a>
                                    </div>
                                </div>



                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::List Widget 2-->
                    </div>
                </div>
                <!--<div class="row">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#getingstartedpopup">Geting Started Popup </button>
        </div>-->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted(){
        // window.location.reload()
    }
}
</script>
<style>
    body {
        /* font-family: 'Lato', sans-serif; */
    }

    .overlay {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0, 0.9);
        overflow-x: hidden;
        transition: 0.5s;
    }

    .overlay-content {
        position: relative;
        top: 25%;
        width: 100%;
        text-align: center;
    }

    .overlay a {
        padding: 8px;
        text-decoration: none;
        font-size: 36px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }

        .overlay a:hover, .overlay a:focus {
            color: #f1f1f1;
        }

    .overlay .closebtn {
        position: absolute;
        top: 80px;
        right: 45px;
        font-size: 60px;
    }

    @media screen and (max-height: 450px) {
        .overlay a {
            font-size: 20px
        }

        .overlay .closebtn {
            font-size: 40px;
            top: 15px;
            right: 35px;
            color: red;
        }
    }
</style>
