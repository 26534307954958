<template>
    <div class="content flex-row-fluid" id="kt_content">
        <form class="selectaccountype bg-w pd-30 mx-auto mw-800px w-100 pt-15 pb-10 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate"
            id="kt_create_account_form">
            <!--begin::Step 1-->
            <div class="current" data-kt-stepper-element="content">
                <!--begin::Wrapper-->
                <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-10 pb-lg-15">
                        <!--begin::Title-->
                        <h2 class="fw-bolder d-flex align-items-center text-dark heading">
                            Choose Account Type
                        </h2>
                    </div>
                    <!--end::Heading-->
                    <!--begin::Input group-->
                    <div class="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <!--begin::Row-->
                        <div class="row chooseaccount">
                            <!--begin::Col-->
                            <div class="col-lg-6 chosedis">
                                <input type="radio" class="btn-check" name="account_type1" value="0" v-model="account.Account_type" id="Organization" />
                                <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for="Organization">
                                    <i class="fas fa-sitemap"></i>
                                    <span class="d-block fw-bold text-start">
                                        <span class="text-dark fw-bolder d-block fs-2 mb-2">{{ $t('Organization') }}</span>
                                    </span>
                                    <!--end::Info-->
                                </label>
                                <!--end::Option-->
                            </div>
                            <!--end::Col-->
                            <div class="col-lg-6">
                                <h4>What is an {{ $t('Organization') }}?</h4>
                                <p>
                                    A business that wants to receive
                                    supply/ services from vendors
                                </p>
                            </div>
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div class="row chooseaccount">
                            <!--begin::Col-->
                            <div class="col-lg-6 chosedis">
                                <input type="radio" class="btn-check" name="account_type1" value="1" id="Supplier" v-model="account.Account_type" />
                                <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for="Supplier">
                                    <i class="fas fa-chart-area"></i>
                                    <span class="d-block fw-bold text-start">
                                        <span class="text-dark fw-bolder d-block fs-2 mb-2">Suppliers</span>
                                    </span>
                                    <!--end::Info-->
                                </label>
                                <!--end::Option-->
                            </div>
                            <!--end::Col-->
                            <div class="col-lg-6">
                                <h4>What is a Suppliers?</h4>
                                <p>
                                    A business that wants to offer
                                    supply/services to {{ $t('Organization') }}
                                </p>
                            </div>
                        </div>
                        <div class="row chooseaccount">
                            <!--begin::Col-->
                            <div class="col-lg-6 chosedis">
                                <input type="radio" class="btn-check" name="account_type1" value="2" id="Freelancer" v-model="account.Account_type" />
                                <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for="Freelancer">
                                    <i class="fas fa-laptop-house"></i>
                                    <span class="d-block fw-bold text-start">
                                        <span class="text-dark fw-bolder d-block fs-2 mb-2">{{ $t('Freelancer') }}</span>
                                    </span>
                                    <!--end::Info-->
                                </label>
                                <!--end::Option-->
                            </div>
                            <!--end::Col-->
                            <div class="col-lg-6">
                                <h4>What is a {{ $t('Freelancer') }}?</h4>
                                <p>
                                    An individual, who wants to offer his/her
                                    services without registering a company.
                                </p>
                            </div>
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div class="row chooseaccount">
                            <!--begin::Col-->
                            <div class="col-lg-6 chosedis">
                                <input type="radio" class="btn-check" name="account_type1" v-model="account.Account_type" value="3" id="AuditFirm" />
                                <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for="AuditFirm">
                                    <i class="fas fa-calculator"></i>
                                    <span class="d-block fw-bold text-start">
                                        <span class="text-dark fw-bolder d-block fs-2 mb-2">{{ $t('AuditFirm') }}</span>
                                    </span>
                                    <!--end::Info-->
                                </label>
                                <!--end::Option-->
                            </div>
                            <!--end::Col-->
                            <div class="col-lg-6">
                                <h4>What is an {{ $t('AuditFirm') }}?</h4>
                                <p>
                                    A firm to Audit Suppliers for various
                                    {{ $t('compliance') }} criteria and certificates
                                </p>
                            </div>
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div class="row chooseaccount">
                            <!--begin::Col-->
                            <div class="col-lg-6 chosedis" style="cursor: no-drop;">
                                <input type="radio" class="btn-check" name="account_type1" value="4" id="Regulator" v-model="account.Account_type" disabled />
                                <label disabled class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
                                    for="Regulator">
                                    <i class="fas fa-registered"></i>
                                    <span class="d-block fw-bold text-start">
                                        <span class="text-dark fw-bolder d-block fs-2 mb-2">{{ $t('Regulator') }}</span>
                                    </span>
                                    <!--end::Info-->
                                </label>
                                <!--end::Option-->
                            </div>
                            <!--end::Col-->
                            <div class="col-lg-6">
                                <h4>What is a {{ $t('Regulator') }}?</h4>
                                <p>
                                    An Authority that offers and verifies
                                    {{ $t('compliance') }} criteria and certificates
                                </p>
                            </div>
                        </div>
                        <!--end::Row-->
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <div class="text-right loginbtn">
                                    <button
                                        type="button"
                                        @click="saveAccount()"
                                        class="btn btn-lg btn-primary mb-5"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!--end::Input group-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Step 1-->
        </form>
    </div>

</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data()
    {
        return {
            rform: {},
            account: {
                Account_type: null,
                Account_type_name: "Orgnisation",
            },
            types: {
                Organization: 0,
                Supplier: 1,
                Freelancer: 2,
                AuditFirm: 3,
                Regulator: 4,
            },
            email: null,

        }
    },
    computed: {
        ...mapGetters({
            signUpForm: "signup/getSignupData",
        }),

    },
    watch: {
        'account.Account_type': function ()
        {
            this.saveAccount()
        }
    },
    created()
    {
        this.rform = this.signUpForm
        // this.account=this.rform?.RegistrationD[0]
    },
    methods: {

        ...mapActions({
            setCountry: "category/setCountry",
            setDataSignUp: "signup/setDataSignUp",
            setAccpuntType: "signup/setAccpuntType",
        }),

        saveAccount()
        {
            if (this.account.Account_type == 0) this.account.Account_type_name = "Organization"
            else if (this.account.Account_type == 1) this.account.Account_type_name = "Supplier"
            else if (this.account.Account_type == 2) this.account.Account_type_name = "Freelancer"
            else if (this.account.Account_type == 3) this.account.Account_type_name = "Audit Firm"
            else if (this.account.Account_type == 4) this.account.Account_type_name = "Regulator"
            this.rform.RegistrationD[0].Account_type = this.account.Account_type
            this.rform.RegistrationD[0].Account_type_name = this.account.Account_type_name
            let data = {
                Account_type: this.account.Account_type,
                Account_type_name: this.account.Account_type_name
            }
            // console.log(data)
            this.setAccpuntType(data)

            this.setDataSignUp(this.rform).then(() =>
            {

                if (this.account.Account_type == 2)
                {
                    localStorage.clear()
                    localStorage.setItem('account_type', JSON.stringify(data))
                    localStorage.setItem('email', this.email)
                    this.$router.push({ name: "signUpWith" })
                } else
                {
                    localStorage.clear()
                    localStorage.setItem('account_type', JSON.stringify(data))

                    this.$router.push({ name: "MainSignUp" })
                }

            });
        },

    },
    mounted()
    {

        this.setCountry()
        let url_string = window.location.href
        let url = new URL(url_string);
        let account_type = url.searchParams.get("account_type");
        let email = url.searchParams.get("email");
        let vat_number = url.searchParams.get("vat_number");
        let company_name = url.searchParams.get("company_name");
        let Fullname = url.searchParams.get("Fullname");
        let Designation = url.searchParams.get("Designation");
        let Company_name = url.searchParams.get("Company_name");
        let Country_id = url.searchParams.get("Country_id");
        let bank_name = url.searchParams.get("bank_name");
        let bank_customer_name = url.searchParams.get("bank_customer_name");
        let account_name = url.searchParams.get("account_name");
        let bank_iban = url.searchParams.get("bank_iban");
        let organization_size = url.searchParams.get("organization_size");
        let Website_url = url.searchParams.get("Website_url");
        let cr_number = url.searchParams.get("cr_number");


        if (email)
        {
            this.email = email
        }

        let Accounts = ["Organization", "Supplier", "Freelancer", "AuditFirm", "Regulator",];
        let user_id = url.searchParams.get("user_id");


        if (user_id)
        {
            this.$store.dispatch('signup/GetBasicData', user_id)
            let data = {
                Account_type: account_type,
                Account_type_name: Accounts[account_type]
            }
            this.$store.state.signup.signaccountType = account_type;

            this.$store.state.signup.user_id = user_id;

            this.$store.state.signup.signupForm.RegistrationD[0].Account_type_name = Accounts[account_type]

            this.$store.state.signup.signupForm.RegistrationD[0].Account_type = account_type

            this.$store.state.signup.current_step = 3
            this.setAccpuntType(data)
            this.$router.push({ name: "MainSignUp" })
        } else if (account_type && !user_id)
        {
            if (this.email)
            {
                this.$store.state.signup.signupForm.Email = this.email
            }
            if(vat_number)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].vat_number = vat_number
            }
            if(company_name)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].company_name = company_name
            }

            if(Fullname)
            {
                this.$store.state.signup.signupForm.Fullname = Fullname
            }

            if(Designation)
            {
                this.$store.state.signup.signupForm.Designation = Designation
            }

            if(Company_name)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].Company_name = Company_name
            }

            if(Country_id)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].Country_id = Country_id
            }

            if(bank_name)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].bank_name = bank_name
            }

            if(bank_customer_name)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].bank_customer_name = bank_customer_name
            }

            if(account_name)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].account_name = account_name
            }

            if(bank_iban)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].bank_iban = bank_iban
            }

            if(organization_size)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].Company_size = organization_size
            }

            if(Website_url)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].Website_url = Website_url
            }

            if(cr_number)
            {
                this.$store.state.signup.signupForm.RegistrationD[0].CR_number = cr_number??''
            }


            if (account_type in this.types)
            {
                this.account.Account_type = this.types[account_type]
                this.saveAccount()
            }
        }
    }
}
</script>
<style scoped>
html[lang="ar"] .main_app .heading {
    justify-content: end;
}

html[lang="ar"] #kt_content {
    text-align: right !important;
}

html[lang="ar"] #kt_content label {
    justify-content: end;
}



@media (max-width:992px) {
    .chooseaccount {
        border-radius: 11px;
        box-shadow: 0px 0px 5px #e2e2e2;
        margin-bottom: 10px;
    }

    .chooseaccount:hover .chosedis {
        background-color: #F1FAFF !important;
    }

    .btn.btn-outline.btn-outline-dashed.btn-outline-default {
        border-width: initial !important;
        border-style: initial !important;
        color: none !important;
        border-color: initial !important;
    }

    .p-7 {
        padding: 1.75rem 0 !important;
    }

    .mb-10 {
        margin-bottom: 0px !important;
    }

    .chooseaccount .chosedis {
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 10px;
    }
}
</style>
